import { TFunction } from 'i18next';

import { ContributionStateModel } from '../../models/contribution.models';
import * as rootSelectors from '../root.selectors';
import { RootStateModel } from '../root.store';

const contributionSelector = (state: RootStateModel): ContributionStateModel =>
  state.contribution;

export const selectPlaces = rootSelectors.createSelectPlaces(
  contributionSelector,
);

export const selectBuildings = rootSelectors.createSelectBuildings(
  contributionSelector,
);

export const selectPlacesData = rootSelectors.createSelectPlacesData(
  contributionSelector,
);

export const selectPlayerName = rootSelectors.createSelectPlayerName(
  contributionSelector,
);

export const selectBuildingLevels = rootSelectors.createSelectBuildingLevels(
  contributionSelector,
);

export const selectSelectedFactor = rootSelectors.createSelectSelectedFactor(
  contributionSelector,
);

export const selectOwnContribution = rootSelectors.createSelectOwnContribution(
  contributionSelector,
);

export const selectConfigurationHistory = rootSelectors.createSelectConfigurationHistory(
  contributionSelector,
);

export const makeSelectSummaryLines = (t: TFunction) =>
  rootSelectors.createSelectSummaryLines(t, contributionSelector);

export const makeSelectPlacesSummaryLines = (t: TFunction) =>
  rootSelectors.createSelectPlacesSummaryLines(t, contributionSelector);

export const selectSelectedBuildingName = rootSelectors.createSelectSelectedBuildingName(
  contributionSelector,
);

export const selectSelectedBuildingLevelTo = rootSelectors.createSelectSelectedBuildingLevelTo(
  contributionSelector,
);

export const selectSelectedBuildingLevelFrom = rootSelectors.createSelectSelectedBuildingLevelFrom(
  contributionSelector,
);

export const selectPlacesOwnContributionTotal = rootSelectors.createSelectPlacesOwnContributionTotal(
  contributionSelector,
);

export const selectPlacesCostContributionTotal = rootSelectors.createSelectPlacesCostContributionTotal(
  contributionSelector,
);

export const selectPlacesRewardContributionTotal = rootSelectors.createSelectPlacesRewardContributionTotal(
  contributionSelector,
);

export const selectBuildingLevelCostTotal = rootSelectors.createSelectBuildingLevelCostTotal(
  contributionSelector,
);
