import React from 'react';

import Paper from '@material-ui/core/Paper';

import useStyles from './AdSenseSquare.styles';

declare global {
  interface Window {
    adsbygoogle: Array<Object>;
  }
}

function AdSenseSquare() {
  const classes = useStyles();

  React.useEffect(() => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  }, []);

  return (
    <Paper className={classes.root}>
      <ins
        style={{
          width: '100%',
          height: '100%',
          display: 'block',
          textAlign: 'center',
        }}
        className="adsbygoogle"
        data-ad-slot="4558563708"
        data-ad-client="ca-pub-8178105098003415"
        data-ad-format="auto"
        data-full-width-responsive="false"
      />
    </Paper>
  );
}

export default AdSenseSquare;
