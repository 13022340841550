import { TFunction } from 'i18next';

import { PlaybookStateModel } from '../../models/playbook.models';
import * as rootSelectors from '../root.selectors';
import { RootStateModel } from '../root.store';

/**
 * Playbook selector
 * @param {RootStateModel} state - The root state.
 */
const playbookSelector = (state: RootStateModel): PlaybookStateModel =>
  state.playbook;

/**
 * Select buildings.
 */
export const selectBuildings = rootSelectors.createSelectBuildings(
  playbookSelector,
);

/**
 * Select player name.
 */
export const selectPlayerName = rootSelectors.createSelectPlayerName(
  playbookSelector,
);

/**
 * Select building levels.
 */
export const selectBuildingLevels = rootSelectors.createSelectBuildingLevels(
  playbookSelector,
);

/**
 * Select selected factor.
 */
export const selectSelectedFactor = rootSelectors.createSelectSelectedFactor(
  playbookSelector,
);

/**
 * Select selected building name.
 */
export const selectSelectedBuildingName = rootSelectors.createSelectSelectedBuildingName(
  playbookSelector,
);

/**
 * Select selected building level to.
 */
export const selectSelectedBuildingLevelTo = rootSelectors.createSelectSelectedBuildingLevelTo(
  playbookSelector,
);

/**
 * Select selected building level from.
 */
export const selectSelectedBuildingLevelFrom = rootSelectors.createSelectSelectedBuildingLevelFrom(
  playbookSelector,
);

export const selectPlacesOwnContributionTotal = rootSelectors.createSelectPlacesOwnContributionTotal(
  playbookSelector,
);

/**
 * Make select summary lines.
 * @param {TFunction} t - The translation function.
 */
export const makeSelectSummaryLines = (t: TFunction) =>
  rootSelectors.createSelectSummaryLines(t, playbookSelector);

/**
 * Make select places data with props.
 */
export const makeSelectPlacesDataWithProps = () =>
  rootSelectors.createSelectPlacesDataWithProps(playbookSelector);

/**
 * Make select summary lines with props.
 * @param {TFunction} t - The translation function.
 */
export const makeSelectSummaryLinesWithProps = (t: TFunction) => () =>
  rootSelectors.createSelectSummaryLinesWithProps(t, playbookSelector);

/**
 * Make select places own contribution total.
 */
export const makeSelectPlacesOwnContributionTotal = () =>
  rootSelectors.createSelectPlacesOwnContributionTotalWithProps(
    playbookSelector,
  );
