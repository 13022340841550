import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';

import pink from '@material-ui/core/colors/pink';

import {
  RANKING_LOCAL_STORAGE_KEY_BUILDING,
  RANKING_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
} from '../../constants/app.constants';
import { BuildingRouterParamModel } from '../../models/common.models';
import {
  selectIsWebSocketConnected,
  selectPlayerName,
} from '../../store/ranking/ranking.selectors';
import {
  handleChangePlayerName,
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelTo,
} from '../../store/ranking/ranking.store';
import { useTypedSelector } from '../../store/root.store';
import generateRoomId from '../../utils/generateRoomId';
import { getValidBuilding } from '../../utils/getData';
import setPlayerNameState from '../../utils/setPlayerNameState';
import { setBuildingRouterState } from '../../utils/setRouterState';

import RankingConfiguration from './RankingConfiguration';
import RankingContent from './RankingContent';

import useStyles from './Ranking.styles';

function Ranking() {
  const classes = useStyles();
  const params = useParams<BuildingRouterParamModel>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const playerName = useTypedSelector(selectPlayerName);
  const isWebSocketConnected = useTypedSelector(selectIsWebSocketConnected);

  React.useEffect(() => {
    const validBuilding = params.roomId && getValidBuilding(params.roomId);

    if (validBuilding) {
      params.buildingName = params.roomId;
    }

    setPlayerNameState(handleChangePlayerName, dispatch);

    setBuildingRouterState(
      params,
      {
        handleChangeSelectedBuilding,
        handleChangeSelectedBuildingLevel: handleChangeSelectedBuildingLevelTo,
      },
      {
        buildingNameKey: RANKING_LOCAL_STORAGE_KEY_BUILDING,
        buildingLevelKey: RANKING_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
      },
      dispatch,
    );

    if (params.buildingName) {
      const roomId = generateRoomId();

      history.replace(`/r/${roomId}`);
    }
  }, [dispatch, history, params]);

  function renderContent() {
    if (!playerName) {
      return <RankingConfiguration />;
    }

    if (isWebSocketConnected) {
      return <RankingContent />;
    }

    return (
      <div className={classes.loader}>
        <PuffLoader size={100} color={pink.A700} />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {t('main.ranking.title')} [App] | {t('main.app.title')}
        </title>

        <meta name="description" content={t('main.ranking.description')} />
      </Helmet>

      {renderContent()}
    </div>
  );
}

export default Ranking;
