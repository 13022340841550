import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import toNumber from 'lodash/toNumber';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

type ContributionSelectionProps = {
  selectedContribution: number;
  handleChangeSelectedContribution: (selectedContribution: number) => void;
};

function ContributionSelection({
  selectedContribution,
  handleChangeSelectedContribution,
}: ContributionSelectionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Paper elevation={0}>
      <Typography color="textSecondary" variant="caption" component="p">
        {t('investment.overview.contribution')}
      </Typography>

      <TextField
        type="number"
        value={selectedContribution === 0 ? '' : selectedContribution}
        fullWidth
        inputProps={{
          inputMode: 'numeric',
        }}
        onFocus={(event) => event.target.select()}
        onChange={(event) =>
          dispatch(
            handleChangeSelectedContribution(toNumber(event.target.value)),
          )
        }
      />
    </Paper>
  );
}

export default ContributionSelection;
