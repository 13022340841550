import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#424242',
  },

  title: {
    flexGrow: 1,
  },

  toolbar: {},

  language: {
    padding: theme.spacing(1, 2),
    minWidth: 0,
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
