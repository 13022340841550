import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import range from 'lodash/range';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING,
  PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
} from '../../constants/app.constants';
import { BuildingRouterParamModel } from '../../models/common.models';
import {
  selectSelectedBuildingLevelFrom,
  selectSelectedBuildingLevelTo,
  selectSelectedBuildingName,
} from '../../store/playbook/playbook.selectors';
import {
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelTo,
} from '../../store/playbook/playbook.store';
import { useTypedSelector } from '../../store/root.store';
import { setBuildingRouterState } from '../../utils/setRouterState';
import AdSenseSquare from '../common/AdSenseSquare';
import BuildingImage from '../common/BuildingImage';

import PlaybookLevel from './PlaybookLevel';
import PlaybookSettings from './PlaybookSettings';

import useStyles from './Playbook.styles';

function Playbook() {
  const classes = useStyles();
  const { t } = useTranslation();
  const params = useParams<BuildingRouterParamModel>();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setBuildingRouterState(
      params,
      {
        handleChangeSelectedBuilding,
        handleChangeSelectedBuildingLevel: handleChangeSelectedBuildingLevelTo,
      },
      {
        buildingNameKey: PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING,
        buildingLevelKey: PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
      },
      dispatch,
    );

    // Redirect without params
    if (params.buildingName) {
      history.replace('/p');
    }
  }, [dispatch, history, params]);

  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);
  const selectedBuildingLevelTo = useTypedSelector(
    selectSelectedBuildingLevelTo,
  );
  const selectedBuildingLevelFrom = useTypedSelector(
    selectSelectedBuildingLevelFrom,
  );

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Helmet>
        <title>
          {t('main.playbook.title')} [App] | {t('main.app.title')}
        </title>

        <meta name="description" content={t('main.playbook.description')} />
      </Helmet>

      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="h5" component="h2">
            {t('main.playbook.title')}
          </Typography>

          <Typography color="textSecondary" variant="body2" gutterBottom>
            {t('main.playbook.description')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <BuildingImage selectedBuildingName={selectedBuildingName} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <PlaybookSettings />
        </Grid>

        {range(selectedBuildingLevelFrom, selectedBuildingLevelTo + 1).map(
          (levelNumber) => (
            <Grid key={levelNumber} item xs={12} sm={12} md={6} lg={4}>
              <PlaybookLevel
                levelNumber={levelNumber}
                selectedBuildingLevelTo={levelNumber}
              />
            </Grid>
          ),
        )}

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <AdSenseSquare />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Playbook;
