import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    marginTop: theme.spacing(2),
  },

  cardActions: {
    alignSelf: 'end',
  },
}));

export default useStyles;
