import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './root.reducer';
import rootSaga from './root.saga';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware(), sagaMiddleware],
});

if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept('./root.reducer', () => {
    const newRootReducer = require('./root.reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

sagaMiddleware.run(rootSaga);

export type RootStateModel = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootStateModel> = useSelector;

export default store;
