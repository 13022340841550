import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
  },

  button: {
    textTransform: 'none',
  },

  buttonWrapper: {
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },

  summary: {
    wordBreak: 'break-all',
  },
}));

export default useStyles;
