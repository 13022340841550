import React from 'react';
import { useTranslation } from 'react-i18next';

import join from 'lodash/join';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';

import copyToClipboard from 'copy-to-clipboard';

import {
  makeSelectPlacesDataWithProps,
  makeSelectPlacesOwnContributionTotal,
  makeSelectSummaryLinesWithProps,
} from '../../store/playbook/playbook.selectors';
import { useTypedSelector } from '../../store/root.store';
import trackEvent from '../../utils/trackEvent';

import useStyles from './PlaybookLevel.styles';

type PlaybookLevelProps = {
  levelNumber: number;
  selectedBuildingLevelTo: number;
};

function PlaybookLevel({
  levelNumber,
  selectedBuildingLevelTo,
}: PlaybookLevelProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const selectPlacesDataWithProps = React.useMemo(
    makeSelectPlacesDataWithProps,
    [],
  );

  const selectSummaryLinesWithProps = React.useMemo(
    () => makeSelectSummaryLinesWithProps(t),
    [t],
  );

  const selectPlacesOwnContributionTotalWithProps = React.useMemo(
    makeSelectPlacesOwnContributionTotal,
    [],
  );

  const placesData = useTypedSelector((state) =>
    selectPlacesDataWithProps(state, { selectedBuildingLevelTo }),
  );
  const summaryLines = useTypedSelector((state) =>
    selectSummaryLinesWithProps()(state, { selectedBuildingLevelTo }),
  );
  const placesOwnContributionTotal = useTypedSelector((state) =>
    selectPlacesOwnContributionTotalWithProps(state, {
      selectedBuildingLevelTo,
    }),
  );

  return (
    <Card className={classes.root}>
      <CardContent>
        <Button
          variant="outlined"
          className={classes.button2}
          onClick={() => {
            trackEvent('Copy to Clipboard', 'Playbook Level [top]');

            return copyToClipboard(join(summaryLines, ' '));
          }}
        >
          <Typography variant="h5" component="h3">
            L{levelNumber}
          </Typography>
        </Button>

        <List dense>
          {Object.keys(placesData).map((placeNumber) => {
            const { costContribution, ownContributionSubtotal } = placesData[
              placeNumber
            ];

            return (
              <ListItem key={placeNumber}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>{placeNumber}</Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={costContribution}
                  secondary={`${ownContributionSubtotal} / ${placesOwnContributionTotal}`}
                  primaryTypographyProps={{
                    variant: 'h6',
                  }}
                />

                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    disabled
                    aria-label="copy"
                    onClick={() => {
                      trackEvent('Copy to Clipboard', 'Playbook Place');

                      return copyToClipboard(costContribution.toString());
                    }}
                  >
                    <PlayForWorkIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </CardContent>

      <CardActions>
        <Button
          size="small"
          className={classes.button}
          onClick={() => {
            trackEvent('Copy to Clipboard', 'Playbook Level [bottom]');

            return copyToClipboard(join(summaryLines, ' '));
          }}
        >
          Copy level
        </Button>
      </CardActions>
    </Card>
  );
}

export default PlaybookLevel;
