import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import first from 'lodash/first';
import pickBy from 'lodash/pickBy';
import toNumber from 'lodash/toNumber';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { RankingRouterParamModel } from '../../models/common.models';
import {
  selectClosedAt,
  selectCreatedAt,
  selectIsOwner,
  selectPlacesData,
  selectPlayerName,
  selectRankings,
  selectSelectedBuildingName,
} from '../../store/ranking/ranking.selectors';
import {
  handleChangePlaceAllocation,
  handleChangePlaceConfiguration,
  handleChangeRoomId,
} from '../../store/ranking/ranking.store';
import { useTypedSelector } from '../../store/root.store';
import BuildingImage from '../common/BuildingImage';

import RankingSettings from './RankingSettings';

import useStyles from './RankingContent.styles';

function RankingContent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { roomId } = useParams<RankingRouterParamModel>();

  const isOwner = useTypedSelector(selectIsOwner);
  const closedAt = useTypedSelector(selectClosedAt);
  const rankings = useTypedSelector(selectRankings);
  const createdAt = useTypedSelector(selectCreatedAt);
  const placesData = useTypedSelector(selectPlacesData);
  const playerName = useTypedSelector(selectPlayerName);
  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);

  const reservedPlaceNumber = toNumber(
    first(Object.keys(pickBy(rankings, ['playerName', playerName]))),
  );

  React.useEffect(() => {
    dispatch(handleChangeRoomId(roomId));
  }, [dispatch, roomId]);

  const isDisabled = (placeNumber: number) => {
    const placeIsEnabled = rankings[placeNumber].isEnabled;
    const placePlayerName = rankings[placeNumber].playerName;

    if (closedAt || !createdAt || !placeIsEnabled) {
      return true;
    }

    if (placePlayerName && placePlayerName !== playerName) {
      return true;
    }

    // noinspection RedundantIfStatementJS
    if (reservedPlaceNumber && reservedPlaceNumber !== placeNumber) {
      return true;
    }

    return false;
  };

  const renderRankingAction = (placeNumber: number) => {
    const isEnabled = rankings[placeNumber].isEnabled;
    const isPlayerName = playerName === rankings[placeNumber].playerName;

    if (isOwner) {
      return (
        <Switch
          name={`configuration:${placeNumber}`}
          color="primary"
          checked={isEnabled}
          disabled={!!closedAt || !!rankings[placeNumber].playerName}
          onChange={() => {
            dispatch(
              handleChangePlaceConfiguration({
                place: placeNumber,
                isEnabled: !isEnabled,
              }),
            );
          }}
        />
      );
    }

    if (isPlayerName) {
      return (
        <Button
          name={`allocation:${placeNumber}`}
          color="secondary"
          variant="outlined"
          disabled={isDisabled(placeNumber)}
          onClick={() => {
            dispatch(
              handleChangePlaceAllocation({
                place: placeNumber,
                playerName: '',
              }),
            );
          }}
        >
          {t('ranking.content.release')}
        </Button>
      );
    }

    return (
      <Button
        name={`allocation:${placeNumber}`}
        color="primary"
        variant="outlined"
        disabled={isDisabled(placeNumber)}
        onClick={() => {
          dispatch(
            handleChangePlaceAllocation({ place: placeNumber, playerName }),
          );
        }}
      >
        {t('ranking.content.reserve')}
      </Button>
    );
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="h5" component="h2">
            {t('main.ranking.title')}
          </Typography>

          <Typography color="textSecondary" variant="body2" gutterBottom>
            {t('main.ranking.description')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <BuildingImage selectedBuildingName={selectedBuildingName} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <RankingSettings />
        </Grid>
      </Grid>

      {createdAt && (
        <TableContainer component={Paper} className={classes.paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('ranking.content.place')}</TableCell>
                <TableCell align="center">
                  {t('ranking.content.contribution')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.keys(placesData).map((placeNumber) => (
                <TableRow key={placeNumber} hover>
                  <TableCell width="40%">
                    {rankings[placeNumber].playerName || `P${placeNumber}`}
                  </TableCell>

                  <TableCell align="center">
                    {placesData[placeNumber].costContribution}
                  </TableCell>

                  <TableCell align="right">
                    {closedAt && (
                      <Typography color="textSecondary" variant="body2">
                        {t('ranking.content.closed')}
                      </Typography>
                    )}

                    {!closedAt && renderRankingAction(toNumber(placeNumber))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
}

export default RankingContent;
