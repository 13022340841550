import find from 'lodash/find';
import max from 'lodash/max';

import config from '../data/config.json';
import {
  CommonPlaceStateModel,
  CommonStateModel,
  ConfigurationHistoryModel,
} from '../models/common.models';
import { InvestmentStateModel } from '../models/investment.models';

import { setLocalStorage } from './localStorage';

export function changePlayerName(
  state: CommonPlaceStateModel,
  playerName: string,
  localStorageKey = '',
) {
  state.playerName = playerName;

  if (localStorageKey) {
    setLocalStorage(localStorageKey, playerName);
  }
}

export function changeSelectedBonus(
  state: InvestmentStateModel,
  selectedBonus: number,
  localStorageKey = '',
) {
  state.selectedBonus = selectedBonus;

  if (localStorageKey) {
    setLocalStorage(localStorageKey, selectedBonus.toString());
  }
}

export function changeSelectedFactor(
  state: CommonPlaceStateModel,
  selectedFactor: number,
  localStorageKey = '',
) {
  state.selectedFactor = selectedFactor;

  for (let place in state.places) {
    if (state.places.hasOwnProperty(place)) {
      state.places[place].factor = selectedFactor;
    }
  }

  if (localStorageKey) {
    setLocalStorage(localStorageKey, selectedFactor.toString());
  }
}

export function changeSelectedBuilding(
  state: CommonStateModel,
  selectedBuilding: string,
  localStorageKey = '',
) {
  state.selectedBuilding = selectedBuilding;

  if (localStorageKey) {
    setLocalStorage(localStorageKey, selectedBuilding);
  }
}

/**
 * Change selected building level to.
 * @param {CommonStateModel} state - The state.
 * @param {number} selectedBuildingLevelTo - The selected building level to.
 * @param {string} [localStorageKey] - The local storage key to store the new building level.
 */
export function changeSelectedBuildingLevelTo(
  state: CommonStateModel,
  selectedBuildingLevelTo: number,
  localStorageKey = '',
) {
  const maxSelectedBuildingLevelTo = max([
    1,
    selectedBuildingLevelTo,
  ]) as number;

  state.selectedBuildingLevelTo = maxSelectedBuildingLevelTo;

  if (localStorageKey) {
    setLocalStorage(localStorageKey, maxSelectedBuildingLevelTo.toString());
  }

  if (state.selectedBuildingLevelFrom >= maxSelectedBuildingLevelTo) {
    changeSelectedBuildingLevelFrom(state, maxSelectedBuildingLevelTo - 1);
  }
}

export function changeSelectedBuildingLevelToGroup(
  state: CommonStateModel,
  selectedBuildingLevelTo: number,
  localStorageKey = '',
) {
  changeSelectedBuildingLevelTo(
    state,
    selectedBuildingLevelTo,
    localStorageKey,
  );

  changeSelectedBuildingLevelFrom(state, state.selectedBuildingLevelTo - 1);
}

export const changeSelectedBuildingLevelFrom = (
  state: CommonStateModel,
  selectedBuildingLevelFrom: number,
  localStorageKey = '',
) => {
  const maxSelectedBuildingLevelFrom = max([
    0,
    selectedBuildingLevelFrom,
  ]) as number;

  state.selectedBuildingLevelFrom = maxSelectedBuildingLevelFrom;

  if (state.selectedBuildingLevelTo <= maxSelectedBuildingLevelFrom) {
    changeSelectedBuildingLevelTo(
      state,
      maxSelectedBuildingLevelFrom + 1,
      localStorageKey,
    );
  }
};

export function changeSelectedBuildingLevelFromGroup(
  state: CommonStateModel,
  selectedBuildingLevelFrom: number,
  localStorageKey = '',
) {
  changeSelectedBuildingLevelFrom(
    state,
    selectedBuildingLevelFrom,
    localStorageKey,
  );

  changeSelectedBuildingLevelTo(
    state,
    selectedBuildingLevelFrom + 1,
    localStorageKey,
  );
}

export function changeConfigurationHistory(
  state: CommonStateModel,
  configurationHistory: ConfigurationHistoryModel,
  localStorageKey = '',
) {
  if (!find(state.configurationHistory, configurationHistory)) {
    state.configurationHistory.unshift(configurationHistory);

    // @ts-ignore
    const configurationHistorySize: number = config.configurationHistorySize;

    if (state.configurationHistory.length > configurationHistorySize) {
      state.configurationHistory = state.configurationHistory.slice(
        0,
        configurationHistorySize,
      );
    }

    if (localStorageKey) {
      setLocalStorage(
        localStorageKey,
        JSON.stringify(state.configurationHistory),
      );
    }
  }
}
