import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },

  card: {
    height: '100%',
    display: 'grid',
  },

  cardActions: {
    alignSelf: 'end',
  },

  paper: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
