import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},

  loader: {
    top: '50%',
    left: '50%',
    position: 'fixed',
    marginTop: '-50px',
    marginLeft: '-50px',
  },

  adsense: {},
}));

export default useStyles;
