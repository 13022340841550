import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  INVESTMENT_LOCAL_STORAGE_KEY_BUILDING,
  INVESTMENT_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
  LOCAL_STORAGE_KEY_BONUS,
} from '../../constants/app.constants';
import bonuses from '../../data/bonus.json';
import { InvestmentStateModel } from '../../models/investment.models';
import { getCommonInitialState } from '../../utils/getInitialState';
import * as reducerFn from '../../utils/reducerFn';

const initialState = {
  ...getCommonInitialState(),
  bonuses,
  selectedBonus: bonuses[0].bonus,
  selectedHighestBid: 0,
  selectedContribution: 0,
  selectedLevelCostTotal: 0,
};

export const handleChangeSelectedBonus = createAction<number>(
  'investment/handleChangeSelectedBonus',
);

export const handleChangeSelectedBuilding = createAction<string>(
  'investment/handleChangeSelectedBuilding',
);

export const handleChangeSelectedHighestBid = createAction<number>(
  'investment/handleChangeSelectedHighestBid',
);

export const handleChangeSelectedContribution = createAction<number>(
  'investment/handleChangeSelectedContribution',
);

export const handleChangeSelectedLevelCostTotal = createAction<number>(
  'investment/handleChangeSelectedLevelCostTotal',
);

export const handleChangeSelectedBuildingLevelTo = createAction<number>(
  'investment/handleChangeSelectedBuildingLevelTo',
);

export const handleChangeSelectedBuildingLevelFrom = createAction<number>(
  'investment/handleChangeSelectedBuildingLevelFrom',
);

export const investmentReducer = createReducer<InvestmentStateModel>(
  initialState,

  (builder) =>
    builder
      .addCase(handleChangeSelectedBonus, (state, { payload }) =>
        reducerFn.changeSelectedBonus(state, payload, LOCAL_STORAGE_KEY_BONUS),
      )
      .addCase(handleChangeSelectedBuilding, (state, { payload }) =>
        reducerFn.changeSelectedBuilding(
          state,
          payload,
          INVESTMENT_LOCAL_STORAGE_KEY_BUILDING,
        ),
      )
      .addCase(handleChangeSelectedHighestBid, (state, { payload }) => {
        state.selectedHighestBid = payload;
      })
      .addCase(handleChangeSelectedContribution, (state, { payload }) => {
        state.selectedContribution = payload;
      })
      .addCase(handleChangeSelectedLevelCostTotal, (state, { payload }) => {
        state.selectedLevelCostTotal = payload;
      })
      .addCase(handleChangeSelectedBuildingLevelTo, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelToGroup(
          state,
          payload,
          INVESTMENT_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
        ),
      )
      .addCase(handleChangeSelectedBuildingLevelFrom, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelFromGroup(state, payload),
      ),
);
