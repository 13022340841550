import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },

  button: {
    padding: theme.spacing(2),
  },

  costs: {
    display: 'flex',
    alignItems: 'center',
  },

  center: {
    textAlign: 'center',
    alignContent: 'center',
  },

  section1: {},

  section2: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  section3: {
    marginTop: theme.spacing(2),
  },

  section4: {
    marginTop: theme.spacing(2),
  },

  typographyBottom: {
    display: 'flex',
    alignItems: 'end',
  },

  progress: {},

  progressTotalValue: {
    justifyContent: 'flex-end',
  },

  dangerInfo: {
    minHeight: theme.spacing(2.5),
  },
}));

export default useStyles;
