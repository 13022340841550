import React from 'react';

import { PaletteType, ThemeOptions } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/pink';

import { LOCAL_STORAGE_KEY_THEME_TYPE } from '../constants/app.constants';
import config from '../data/config.json';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

function createTheme(options?: ThemeOptions) {
  let type = config.themeType as PaletteType;

  const localStorageType = getLocalStorage(LOCAL_STORAGE_KEY_THEME_TYPE);

  if (localStorageType) {
    type = localStorageType as PaletteType;
  }

  return {
    palette: {
      type,
      primary: blue,
      secondary: pink,
    },
    ...options,
  };
}

function useThemeMode() {
  const [theme, setTheme] = React.useState(createTheme());

  const {
    palette: { type },
  } = theme;

  const toggleThemeMode = () => {
    const updatedType: PaletteType = type === 'light' ? 'dark' : 'light';

    const updatedTheme = {
      ...theme,
      palette: {
        ...theme.palette,
        type: updatedType,
      },
    };

    setTheme(updatedTheme);

    setLocalStorage(LOCAL_STORAGE_KEY_THEME_TYPE, updatedType);
  };

  return [theme, toggleThemeMode] as const;
}

export default useThemeMode;
