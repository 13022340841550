import React from 'react';

import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';

import {
  selectBonuses,
  selectBuildingLevels,
  selectBuildings,
  selectSelectedBonus,
  selectSelectedBuildingLevelFrom,
  selectSelectedBuildingLevelTo,
  selectSelectedBuildingName,
} from '../../store/investment/investment.selectors';
import {
  handleChangeSelectedBonus,
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelFrom,
  handleChangeSelectedBuildingLevelTo,
} from '../../store/investment/investment.store';
import { useTypedSelector } from '../../store/root.store';
import BonusSelection from '../common/BonusSelection';
import BuildingLevelSelection from '../common/BuildingLevelSelection';
import BuildingSelection from '../common/BuildingSelection';

import useStyles from './InvestmentSettings.styles';

function InvestmentSettings() {
  const classes = useStyles();

  const bonuses = useTypedSelector(selectBonuses);
  const buildings = useTypedSelector(selectBuildings);
  const buildingLevels = useTypedSelector(selectBuildingLevels);
  const selectedBonus = useTypedSelector(selectSelectedBonus);
  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);
  const selectedBuildingLevelTo = useTypedSelector(
    selectSelectedBuildingLevelTo,
  );
  const selectedBuildingLevelFrom = useTypedSelector(
    selectSelectedBuildingLevelFrom,
  );

  return (
    <Paper className={classes.root}>
      <BuildingSelection
        buildings={buildings}
        selectedBuildingName={selectedBuildingName}
        handleChangeSelectedBuilding={handleChangeSelectedBuilding}
      />

      <Paper className={classes.paper} elevation={0}>
        <BuildingLevelSelection
          buildingLevels={buildingLevels}
          selectedBuildingLevelTo={selectedBuildingLevelTo}
          selectedBuildingLevelFrom={selectedBuildingLevelFrom}
          canChangeSelectedBuildingLevelTo={false}
          canChangeSelectedBuildingLevelFrom
          handleChangeSelectedBuildingLevelTo={
            handleChangeSelectedBuildingLevelTo
          }
          handleChangeSelectedBuildingLevelFrom={
            handleChangeSelectedBuildingLevelFrom
          }
        />
      </Paper>

      <Divider light variant="middle" className={classes.divider} />

      <BonusSelection
        bonuses={bonuses}
        selectedBonus={selectedBonus}
        handleChangeSelectedBonus={handleChangeSelectedBonus}
      />
    </Paper>
  );
}

export default InvestmentSettings;
