import React from 'react';

import Paper from '@material-ui/core/Paper';

import {
  selectBuildingLevels,
  selectBuildings,
  selectPlayerName,
  selectSelectedBuildingLevelFrom,
  selectSelectedBuildingLevelTo,
  selectSelectedBuildingName,
  selectSelectedFactor,
} from '../../store/playbook/playbook.selectors';
import {
  handleChangePlayerName,
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelFrom,
  handleChangeSelectedBuildingLevelTo,
  handleChangeSelectedFactor,
} from '../../store/playbook/playbook.store';
import { useTypedSelector } from '../../store/root.store';
import BuildingLevelSelection from '../common/BuildingLevelSelection';
import BuildingSelection from '../common/BuildingSelection';
import FactorSelection from '../common/FactorSelection';
import PlayerName from '../common/PlayerName';

import useStyles from './PlaybookSettings.styles';

function PlaybookSettings() {
  const classes = useStyles();

  const buildings = useTypedSelector(selectBuildings);
  const playerName = useTypedSelector(selectPlayerName);
  const buildingLevels = useTypedSelector(selectBuildingLevels);
  const selectedFactor = useTypedSelector(selectSelectedFactor);
  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);
  const selectedBuildingLevelTo = useTypedSelector(
    selectSelectedBuildingLevelTo,
  );
  const selectedBuildingLevelFrom = useTypedSelector(
    selectSelectedBuildingLevelFrom,
  );

  return (
    <Paper className={classes.root}>
      <BuildingSelection
        buildings={buildings}
        selectedBuildingName={selectedBuildingName}
        handleChangeSelectedBuilding={handleChangeSelectedBuilding}
      />

      <Paper className={classes.paper} elevation={0}>
        <FactorSelection
          selectedFactor={selectedFactor}
          handleChangeSelectedFactor={handleChangeSelectedFactor}
        />
      </Paper>

      <Paper className={classes.paper} elevation={0}>
        <BuildingLevelSelection
          buildingLevels={buildingLevels}
          selectedBuildingLevelTo={selectedBuildingLevelTo}
          selectedBuildingLevelFrom={selectedBuildingLevelFrom}
          canChangeSelectedBuildingLevelTo
          canChangeSelectedBuildingLevelFrom
          handleChangeSelectedBuildingLevelTo={
            handleChangeSelectedBuildingLevelTo
          }
          handleChangeSelectedBuildingLevelFrom={
            handleChangeSelectedBuildingLevelFrom
          }
        />
      </Paper>

      <Paper className={classes.paper} elevation={0}>
        <PlayerName
          playerName={playerName}
          handleChangePlayerName={handleChangePlayerName}
        />
      </Paper>
    </Paper>
  );
}

export default PlaybookSettings;
