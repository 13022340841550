import isFunction from 'lodash/isFunction';
import range from 'lodash/range';

import config from '../data/config.json';

/**
 * Create place map.
 * @param {string|number|Object|Function} value - The value data of each place.
 * @return {P} - The created place map.
 */
export function createPlaceMap<P>(
  value?: any | ((key: number, obj?: Object) => any),
): P {
  return range(1, config.places + 1).reduce((obj, key) => {
    let newValue = value;

    if (isFunction(value)) {
      newValue = value(key, obj);
    }

    return {
      ...obj,
      [key]: newValue,
    };
  }, {}) as P;
}
