import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(2),
  },

  paper: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
