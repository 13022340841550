import { Dispatch } from 'react';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { LOCAL_STORAGE_KEY_PLAYER_NAME } from '../constants/app.constants';

import { getLocalStorage } from './localStorage';

/**
 * Set player name state.
 */
function setPlayerNameState(
  action: ActionCreatorWithPayload<string>,
  dispatch: Dispatch<any>,
) {
  const lsPlayerName = getLocalStorage(LOCAL_STORAGE_KEY_PLAYER_NAME);

  if (lsPlayerName) {
    dispatch(action(lsPlayerName));
  }
}

export default setPlayerNameState;
