import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import MuiDrawer from '@material-ui/core/Drawer';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme } from '@material-ui/core/styles';
import MuiSwitch from '@material-ui/core/Switch';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SortIcon from '@material-ui/icons/Sort';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

import trackEvent from '../../utils/trackEvent';

import useStyles from './Drawer.styles';

type DrawerProps = {
  navigationOpen: boolean;
  isThemeModeDark: boolean;
  toggleThemeMode: Function;
  handleDrawerToggle: Function;
};

function Drawer({
  navigationOpen,
  isThemeModeDark,
  toggleThemeMode,
  handleDrawerToggle,
}: DrawerProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <nav className={classes.root}>
      <MuiDrawer
        open={navigationOpen}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        variant="temporary"
        classes={{
          paper: classes.paper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={() => handleDrawerToggle()}
      >
        <div className={classes.header}>
          <img
            alt={t('Forge of Empires - Boost')}
            src="/app/images/favicon/favicon-32x32.png"
          />
        </div>

        <Divider />

        <List>
          <ListItem
            href={`/${i18n.language ? i18n.language : ''}`}
            button
            component={Link}
            onClick={() => {
              trackEvent(
                'Redirect to Page',
                `app [${i18n.language}] -> home [drawer]`,
              );

              return handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>

            <ListItemText primary={t('main.home.title')} />
          </ListItem>

          <ListItem
            to="/c"
            button
            component={RouterLink}
            onClick={() => {
              trackEvent('Redirect to Component', 'contribution [drawer]');

              return handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>

            <ListItemText primary={t('main.contribution.title')} />
          </ListItem>

          <ListItem
            to="/i"
            button
            component={RouterLink}
            onClick={() => {
              trackEvent('Redirect to Component', 'investment [drawer]');

              return handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <EmojiEventsIcon />
            </ListItemIcon>

            <ListItemText primary={t('main.investment.title')} />
          </ListItem>

          <ListItem
            to="/p"
            button
            component={RouterLink}
            onClick={() => {
              trackEvent('Redirect to Component', 'playbook [drawer]');

              return handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>

            <ListItemText primary={t('main.playbook.title')} />
          </ListItem>

          <ListItem
            to="/r"
            button
            component={RouterLink}
            onClick={() => {
              trackEvent('Redirect to Component', 'ranking [drawer]');

              return handleDrawerToggle();
            }}
          >
            <ListItemIcon>
              <SortIcon />
            </ListItemIcon>

            <ListItemText primary={t('main.ranking.title')} />
          </ListItem>
        </List>

        <Divider />

        <List>
          <ListItem>
            <ListItemText primary="Dark mode" />
            <ListItemSecondaryAction>
              <MuiSwitch
                checked={isThemeModeDark}
                onChange={() => {
                  trackEvent(
                    'Toggle Dark Mode',
                    isThemeModeDark ? 'off' : 'on',
                  );

                  return toggleThemeMode();
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </MuiDrawer>
    </nav>
  );
}

export default Drawer;
