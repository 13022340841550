import React from 'react';
import { useTranslation } from 'react-i18next';

import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import trackEvent from '../../utils/trackEvent';

import useStyles from './AppBar.styles';

type AppBarProps = {
  handleDrawerToggle: Function;
};

function AppBar({ handleDrawerToggle }: AppBarProps) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const handleLanguageClick = (language: string) => {
    trackEvent('Language [App]', language);

    return i18n.changeLanguage(language);
  };

  return (
    <MuiAppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          className={classes.menuButton}
          aria-label="open drawer"
          data-tour="appbar-menu"
          onClick={() => handleDrawerToggle()}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" className={classes.title} noWrap>
          <Link
            href={`/${i18n.language ? i18n.language : ''}`}
            color="inherit"
            underline="none"
            onClick={() =>
              trackEvent('Redirect to Page', `app [${i18n.language}] -> home`)
            }
          >
            {t('FoE Boost')}
          </Link>
        </Typography>

        <Button
          size="small"
          color="inherit"
          className={classes.language}
          onClick={() => handleLanguageClick('en')}
        >
          EN
        </Button>

        <Button
          size="small"
          color="inherit"
          className={classes.language}
          onClick={() => handleLanguageClick('de')}
        >
          DE
        </Button>
      </Toolbar>
    </MuiAppBar>
  );
}

export default AppBar;
