import find from 'lodash/find';

import bonuses from '../data/bonus.json';
import alcatraz from '../data/buildings/alcatraz.json';
import arcticOrangery from '../data/buildings/arctic-orangery.json';
import atlantisMuseum from '../data/buildings/atlantis-museum.json';
import atomium from '../data/buildings/atomium.json';
import capeCanaveral from '../data/buildings/cape-canaveral.json';
import capitol from '../data/buildings/capitol.json';
import castelDelMonte from '../data/buildings/castel-del-monte.json';
import cathedralOfAachen from '../data/buildings/cathedral-of-aachen.json';
import chateauFrontenac from '../data/buildings/chateau-frontenac.json';
import colosseum from '../data/buildings/colosseum.json';
import dealCastle from '../data/buildings/deal-castle.json';
import flyingIsland from '../data/buildings/flying-island.json';
import frauenkircheOfDresden from '../data/buildings/frauenkirche-of-dresden.json';
import gaeaStatue from '../data/buildings/gaea-statue.json';
import galataTower from '../data/buildings/galata-tower.json';
import hagiaSophia from '../data/buildings/hagia-sophia.json';
import himejiCastle from '../data/buildings/himeji-castle.json';
import innovationTower from '../data/buildings/innovation-tower.json';
import lighthouseOfAlexandria from '../data/buildings/lighthouse-of-alexandria.json';
import lotusTemple from '../data/buildings/lotus-temple.json';
import notreDame from '../data/buildings/notre-dame.json';
import observatory from '../data/buildings/observatory.json';
import oracleOfDelphi from '../data/buildings/oracle-of-delphi.json';
import rainForestProject from '../data/buildings/rain-forest-project.json';
import royalAlbertHall from '../data/buildings/royal-albert-hall.json';
import saintBasilsCathedral from '../data/buildings/saint-basils-cathedral.json';
import seedVault from '../data/buildings/seed-vault.json';
import spaceCarrier from '../data/buildings/space-carrier.json';
import spaceNeedle from '../data/buildings/space-needle.json';
import stMarksBasilica from '../data/buildings/st-marks-basilica.json';
import starGazer from '../data/buildings/star-gazer.json';
import statueOfZeus from '../data/buildings/statue-of-zeus.json';
import templeOfRelics from '../data/buildings/temple-of-relics.json';
import terracottaArmy from '../data/buildings/terracotta-army.json';
import theArc from '../data/buildings/the-arc.json';
import theBlueGalaxy from '../data/buildings/the-blue-galaxy.json';
import theHabitat from '../data/buildings/the-habitat.json';
import theKraken from '../data/buildings/the-kraken.json';
import theVirgoProject from '../data/buildings/the-virgo-project.json';
import towerOfBabel from '../data/buildings/tower-of-babel.json';
import truceTower from '../data/buildings/truce-tower.json';
import voyagerV1 from '../data/buildings/voyager-v1.json';

/**
 * Get bonuses.
 */
export function getBonuses() {
  return bonuses;
}

/**
 * Get buildings.
 */
export function getBuildings() {
  return [
    alcatraz,
    arcticOrangery,
    atlantisMuseum,
    atomium,
    capeCanaveral,
    capitol,
    castelDelMonte,
    cathedralOfAachen,
    chateauFrontenac,
    colosseum,
    dealCastle,
    flyingIsland,
    frauenkircheOfDresden,
    gaeaStatue,
    galataTower,
    hagiaSophia,
    himejiCastle,
    innovationTower,
    lighthouseOfAlexandria,
    lotusTemple,
    notreDame,
    observatory,
    oracleOfDelphi,
    rainForestProject,
    royalAlbertHall,
    saintBasilsCathedral,
    seedVault,
    spaceCarrier,
    spaceNeedle,
    stMarksBasilica,
    starGazer,
    statueOfZeus,
    templeOfRelics,
    terracottaArmy,
    theArc,
    theBlueGalaxy,
    theHabitat,
    theKraken,
    theVirgoProject,
    towerOfBabel,
    truceTower,
    voyagerV1,
  ];
}

/**
 * Get valid bonus.
 * @param {number} bonus - The bonus.
 * @return The found bonus.
 */
export function getValidBonus(bonus: number) {
  const bonuses = getBonuses();

  return find(bonuses, ['bonus', bonus]);
}

/**
 * Get valid building.
 * @param {string} buildingName - The building name.
 * @return The building by the given building name.
 */
export function getValidBuilding(buildingName: string) {
  const buildings = getBuildings();

  return find(buildings, ['name', buildingName]);
}

/**
 * Get valid building level.
 * @param {string} buildingName - The building name.
 * @param {number} buildingLevel - The building level.
 * @return The building level by the given building name and level number.
 */
export function getValidBuildingLevel(
  buildingName: string,
  buildingLevel: number,
) {
  const validBuilding = getValidBuilding(buildingName);

  if (validBuilding) {
    return find(validBuilding.levels, ['level', buildingLevel]);
  }
}
