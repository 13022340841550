import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import copyToClipboard from 'copy-to-clipboard';

import {
  selectBuildingLevels,
  selectBuildings,
  selectClosedAt,
  selectCreatedAt,
  selectIsOwner,
  selectOwner,
  selectPlayerName,
  selectSelectedBuildingLevelFrom,
  selectSelectedBuildingLevelTo,
  selectSelectedBuildingName,
  selectSelectedFactor,
} from '../../store/ranking/ranking.selectors';
import {
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelFrom,
  handleChangeSelectedBuildingLevelTo,
  handleChangeSelectedFactor,
  handleCloseRanking,
  handleCreateRanking,
} from '../../store/ranking/ranking.store';
import { useTypedSelector } from '../../store/root.store';
import generateRoomId from '../../utils/generateRoomId';
import trackEvent from '../../utils/trackEvent';
import Snackbar from '../base/Snackbar';
import BuildingLevelSelection from '../common/BuildingLevelSelection';
import BuildingSelection from '../common/BuildingSelection';
import FactorSelection from '../common/FactorSelection';

import useStyles from './RankingSettings.styles';

function RankingSettings() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const roomId = generateRoomId();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const owner = useTypedSelector(selectOwner);
  const isOwner = useTypedSelector(selectIsOwner);
  const closedAt = useTypedSelector(selectClosedAt);
  const createdAt = useTypedSelector(selectCreatedAt);
  const buildings = useTypedSelector(selectBuildings);
  const playerName = useTypedSelector(selectPlayerName);
  const buildingLevels = useTypedSelector(selectBuildingLevels);
  const selectedFactor = useTypedSelector(selectSelectedFactor);
  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);
  const selectedBuildingLevelTo = useTypedSelector(
    selectSelectedBuildingLevelTo,
  );
  const selectedBuildingLevelFrom = useTypedSelector(
    selectSelectedBuildingLevelFrom,
  );

  const canClose = isOwner && createdAt && !closedAt;
  const canChange = isOwner && !closedAt && !createdAt;

  return (
    <Card className={classes.root}>
      <CardContent>
        {canChange && (
          <BuildingSelection
            buildings={buildings}
            selectedBuildingName={selectedBuildingName}
            handleChangeSelectedBuilding={handleChangeSelectedBuilding}
          />
        )}

        {!canChange && (
          <TextField
            label={t('settings.building')}
            value={t(`building.${selectedBuildingName}`)}
            fullWidth
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        )}

        <Paper className={classes.paper} elevation={0}>
          {canChange && (
            <FactorSelection
              selectedFactor={selectedFactor}
              handleChangeSelectedFactor={handleChangeSelectedFactor}
            />
          )}

          {!canChange && (
            <TextField
              label={t('settings.factor')}
              value={selectedFactor}
              fullWidth
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Paper>

        <Paper className={classes.paper} elevation={0}>
          <BuildingLevelSelection
            buildingLevels={buildingLevels}
            selectedBuildingLevelTo={selectedBuildingLevelTo}
            selectedBuildingLevelFrom={selectedBuildingLevelFrom}
            canChangeSelectedBuildingLevelTo={canChange}
            canChangeSelectedBuildingLevelFrom={false}
            handleChangeSelectedBuildingLevelTo={
              handleChangeSelectedBuildingLevelTo
            }
            handleChangeSelectedBuildingLevelFrom={
              handleChangeSelectedBuildingLevelFrom
            }
          />
        </Paper>

        <Paper className={classes.paper} elevation={0}>
          <TextField
            label={t('settings.player')}
            value={owner}
            fullWidth
            InputProps={{ readOnly: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Paper>
      </CardContent>

      <CardActions className={classes.cardActions}>
        {canClose && (
          <Button
            color="secondary"
            onClick={() => {
              trackEvent('Ranking Settings', 'Close Ranking');

              return dispatch(handleCloseRanking(playerName));
            }}
          >
            {t('ranking.settings.close')}
          </Button>
        )}

        {canChange && (
          <Button
            color="primary"
            onClick={() => {
              trackEvent('Ranking Settings', 'Create Ranking');

              return dispatch(handleCreateRanking(playerName));
            }}
          >
            {t('ranking.settings.create')}
          </Button>
        )}

        {canClose && (
          <Button
            color="primary"
            onClick={() => {
              trackEvent('Copy to Clipboard', 'Ranking Link');

              copyToClipboard(window.location.href);

              setOpen(true);
            }}
          >
            {t('ranking.settings.copyLink')}
          </Button>
        )}

        <Button
          onClick={() => {
            trackEvent('Ranking Settings', 'Create New Ranking');

            return history.push(`/r/${roomId}`);
          }}
        >
          {t('ranking.settings.createNew')}
        </Button>
      </CardActions>

      <Snackbar
        open={open}
        message={t('snackbar.success.copied')}
        handleClose={() => setOpen(false)}
      />
    </Card>
  );
}

export default RankingSettings;
