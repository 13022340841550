import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import toNumber from 'lodash/toNumber';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

type InvestmentHighestBidProps = {
  selectedHighestBid: number;
  handleChangeSelectedHighestBid: (selectedHighestBid: number) => void;
};

function InvestmentHighestBid({
  selectedHighestBid,
  handleChangeSelectedHighestBid,
}: InvestmentHighestBidProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Paper elevation={0}>
      <Typography color="textSecondary" variant="caption" component="p">
        {t('investment.overview.highestBid')}
      </Typography>

      <TextField
        type="number"
        value={selectedHighestBid === 0 ? '' : selectedHighestBid}
        fullWidth
        inputProps={{
          inputMode: 'numeric',
        }}
        onFocus={(event) => event.target.select()}
        onChange={(event) =>
          dispatch(handleChangeSelectedHighestBid(toNumber(event.target.value)))
        }
      />
    </Paper>
  );
}

export default InvestmentHighestBid;
