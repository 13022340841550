import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import useStyles from './BuildingImage.styles';

type BuildingImageProps = {
  selectedBuildingName: string;
};

function BuildingImage({ selectedBuildingName }: BuildingImageProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const buildingTitle = t(`building.${selectedBuildingName}`);

  return (
    <Card className={classes.card}>
      <Typography variant="h5" component="h2" gutterBottom>
        {buildingTitle}
      </Typography>

      <CardMedia
        image={`/app/images/${selectedBuildingName}.png`}
        title={buildingTitle}
        className={classes.media}
      />
    </Card>
  );
}

export default BuildingImage;
