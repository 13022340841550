import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { eventChannel } from 'redux-saga';
import {
  actionChannel,
  call,
  fork,
  put,
  select,
  take,
} from 'redux-saga/effects';
import io from 'socket.io-client';

import { WebSocketMessageModel } from '../../models/ranking.models';

import { selectPlayerName, selectRoomId } from './ranking.selectors';
import {
  handleChangePlaceAllocation,
  handleChangePlaceConfiguration,
  handleChangeRoomId,
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelTo,
  handleChangeSelectedFactor,
  handleCloseRanking,
  handleCreateRanking,
  handleWebSocketConnected,
  handleWebSocketDisconnected,
  handleWebSocketMessage,
} from './ranking.store';

let socket: SocketIOClient.Socket;
const socketURL = process.env.REACT_APP_SOCKET_URL;

function connect() {
  if (!socketURL) {
    throw new Error('No socket url found.');
  }

  if (socket) {
    return socket;
  }

  socket = io.connect(socketURL, {
    path: '/ws',
  });

  return new Promise((resolve) => {
    socket.on('connect', () => {
      resolve(socket);
    });
  });
}

function* webSocketAction(
  socket: SocketIOClient.Socket,
  // todo: replace any type (https://tech.lalilo.com/redux-saga-and-typescript-doing-it-right)
  payloadAction: ActionCreatorWithPayload<any>,
  webSocketAction: string,
) {
  while (true) {
    const { payload } = yield take(payloadAction);

    const roomId = yield select(selectRoomId);
    const playerName = yield select(selectPlayerName);

    socket.emit(`ws://${webSocketAction}`, { roomId, payload, playerName });
  }
}

function* read(socket: SocketIOClient.Socket) {
  const channel = yield call(subscribe, socket);

  while (true) {
    const action = yield take(channel);

    yield put(action);
  }
}

function subscribe(socket: SocketIOClient.Socket) {
  return eventChannel((emit) => {
    socket.on('connect', () => {
      emit(handleWebSocketConnected());
    });

    socket.on('disconnect', () => {
      emit(handleWebSocketDisconnected());
    });

    socket.on('ws://get-message', (message: WebSocketMessageModel) => {
      emit(handleWebSocketMessage(message));
    });

    return () => {};
  });
}

function* write(socket: SocketIOClient.Socket) {
  // set-owner
  // yield fork(webSocketAction, socket, handleChangePlayerName, 'set-owner');

  // set-factor
  yield fork(webSocketAction, socket, handleChangeSelectedFactor, 'set-factor');

  // set-building
  yield fork(
    webSocketAction,
    socket,
    handleChangeSelectedBuilding,
    'set-building',
  );

  // set-building-level
  yield fork(
    webSocketAction,
    socket,
    handleChangeSelectedBuildingLevelTo,
    'set-building-level',
  );

  // close-ranking
  yield fork(webSocketAction, socket, handleCloseRanking, 'close-ranking');

  // create-ranking
  yield fork(webSocketAction, socket, handleCreateRanking, 'create-ranking');

  // toggle-place-allocation
  yield fork(
    webSocketAction,
    socket,
    handleChangePlaceAllocation,
    'toggle-place-allocation',
  );

  // toggle-place-configuration
  yield fork(
    webSocketAction,
    socket,
    handleChangePlaceConfiguration,
    'toggle-place-configuration',
  );
}

function* handleRoomId(roomId: string) {
  const socket = yield call(connect);

  socket.emit('ws://join-room', { roomId });

  const playerName = yield select(selectPlayerName);

  if (playerName) {
    socket.emit('ws://set-owner', { roomId, playerName });
  }
}

function* watchRoomId() {
  const roomIdChan = yield actionChannel(handleChangeRoomId);

  while (true) {
    const { payload } = yield take(roomIdChan);

    yield call(handleRoomId, payload);
  }
}

export default function* rankingSaga() {
  // non-blocking fork to catch the `handleChangeRoomId` actions
  yield fork(watchRoomId);

  // blocking call to wait for socket connection
  const socket = yield call(connect);
  yield put(handleWebSocketConnected());

  yield fork(read, socket);
  yield fork(write, socket);
}
