import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import toNumber from 'lodash/toNumber';

import Button from '@material-ui/core/Button';
import lightGreen from '@material-ui/core/colors/lightGreen';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

import copyToClipboard from 'copy-to-clipboard';

import { CommonPlaceDataModel } from '../../models/common.models';
import { selectBuildingLevelCostTotal } from '../../store/contribution/contribution.selectors';
import {
  handleChangeConfigurationHistory,
  handleChangePlaceFactor,
  handleChangePlacePaid,
  handleTogglePlaceIsSelected,
} from '../../store/contribution/contribution.store';
import { useTypedSelector } from '../../store/root.store';
import trackEvent from '../../utils/trackEvent';
import Snackbar from '../base/Snackbar';
import FactorSelection from '../common/FactorSelection';

import useStyles from './ContributionPlace.styles';

type ContributionPlaceProps = {
  placeData: CommonPlaceDataModel;
  placePaid: number;
  placeFactor: number;
  placeNumber: number;
  placeIsSelected: boolean;
  placeSummaryLine: string;
};

function ContributionPlace({
  placeData,
  placePaid,
  placeFactor,
  placeNumber,
  placeIsSelected,
  placeSummaryLine,
}: ContributionPlaceProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const {
    ownContribution,
    costContribution,
    rewardContribution,
    ownContributionSubtotal,
    costContributionSubtotal,
  } = placeData;

  const handleSortPlacePaid = () => {};

  const buildingLevelCostTotal = useTypedSelector(selectBuildingLevelCostTotal);

  const contributionSubtotal =
    costContributionSubtotal + ownContributionSubtotal;

  const contribution = contributionSubtotal - costContribution;

  // Calculate minimum investment for the current place
  const minimumInvestment = Math.ceil(
    (buildingLevelCostTotal - contribution) / 2,
  );

  return (
    <Paper className={classes.paper}>
      <div className={classes.section1}>
        <Grid item container justify="space-between" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                trackEvent('Copy to Clipboard', 'Contribution Place');

                copyToClipboard(placeSummaryLine);
                dispatch(handleChangeConfigurationHistory());

                setOpen(true);
              }}
            >
              <Typography variant="h5" component="span">
                P{placeNumber}
              </Typography>
            </Button>
          </Grid>

          <Grid item>
            <Switch
              checked={placeIsSelected}
              onChange={() => {
                trackEvent(
                  'Toggle Contribution Place',
                  placeIsSelected ? 'off' : 'on',
                );

                return dispatch(
                  handleTogglePlaceIsSelected({ place: placeNumber }),
                );
              }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.section2}>
        <Grid
          item
          container
          spacing={2}
          justify="space-around"
          alignItems="center"
        >
          <Grid item className={classes.center}>
            <Typography
              color="textSecondary"
              variant="caption"
              component="p"
              className={classes.center}
            >
              {t('place.label.ownContribution')}
            </Typography>

            <Typography variant="h5" component="p" className={classes.center}>
              {ownContribution > 0 && <>+ {ownContribution}</>}
              {ownContribution <= 0 && (
                <span
                  style={{
                    color: lightGreen.A700,
                  }}
                >
                  <VerifiedUserIcon />
                </span>
              )}
            </Typography>
          </Grid>

          <Grid item>
            <Typography
              color="textSecondary"
              variant="caption"
              component="p"
              className={classes.center}
            >
              {t('place.label.ownContributionSubtotal')}
            </Typography>

            <Typography variant="h5" component="p" className={classes.center}>
              {ownContributionSubtotal}
            </Typography>
          </Grid>
        </Grid>
      </div>

      <div className={classes.progress}>
        <Grid
          container
          justify="space-between"
          spacing={2}
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <LinearProgress value={contribution * 100} variant="determinate" />
          </Grid>

          <Grid item xs>
            <Typography>{contribution}</Typography>
          </Grid>

          <Grid item xs>
            <div className={`${classes.costs} ${classes.progressTotalValue}`}>
              <Typography>{contributionSubtotal}</Typography>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={classes.section3}>
        <Grid
          container
          justify="space-between"
          spacing={2}
          alignItems="flex-start"
        >
          <Grid item xs>
            <Typography color="textSecondary" variant="caption">
              {t('place.costs')}
            </Typography>

            <div className={classes.costs}>
              <Typography variant="h5" component="p">
                {costContribution}
              </Typography>
            </div>

            <Typography
              color="secondary"
              variant="body2"
              className={classes.dangerInfo}
            >
              {minimumInvestment > 0 && minimumInvestment < costContribution
                ? `${minimumInvestment} (${
                    minimumInvestment - costContribution
                  })`
                : ''}
            </Typography>
          </Grid>

          <Grid item xs>
            <Typography color="textSecondary" variant="caption">
              {t('place.paid')}
            </Typography>

            <TextField
              type="number"
              value={placePaid || ''}
              disabled
              fullWidth
              InputProps={{
                readOnly: false,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              onBlur={handleSortPlacePaid}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(
                  handleChangePlacePaid({
                    paid: toNumber(event.target.value),
                    place: placeNumber,
                  }),
                )
              }
              onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  handleSortPlacePaid();
                }
              }}
            />
          </Grid>
        </Grid>
      </div>

      <div className={classes.section4}>
        <Grid
          container
          justify="space-between"
          spacing={2}
          alignItems="flex-start"
        >
          <Grid item xs>
            <Typography color="textSecondary" variant="caption">
              {t('place.reward')}
            </Typography>

            <div className={classes.costs}>
              <Typography color="textSecondary" variant="h6" component="p">
                {rewardContribution}
              </Typography>
            </div>
          </Grid>

          <Grid item xs>
            <FactorSelection
              selectedFactor={placeFactor}
              handleChangeSelectedFactor={(selectedFactor) =>
                dispatch(
                  handleChangePlaceFactor({
                    place: placeNumber,
                    factor: selectedFactor,
                  }),
                )
              }
            />
          </Grid>
        </Grid>
      </div>

      <Snackbar
        open={open}
        message={t('snackbar.success.copied')}
        handleClose={() => setOpen(false)}
      />
    </Paper>
  );
}

export default ContributionPlace;
