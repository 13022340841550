import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import toNumber from 'lodash/toNumber';

import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { InvestmentBonusListModel } from '../../models/investment.models';

import useStyles from './BonusSelection.styles';

type BonusSelectionProps = {
  bonuses: InvestmentBonusListModel;
  selectedBonus: number;
  handleChangeSelectedBonus: (selectedBonus: number) => void;
};

function BonusSelection({
  bonuses,
  selectedBonus,
  handleChangeSelectedBonus,
}: BonusSelectionProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography color="textSecondary" variant="caption" component="p">
        {t('settings.bonus')}
      </Typography>

      <Select
        value={selectedBonus}
        fullWidth
        onChange={(event) =>
          dispatch(handleChangeSelectedBonus(toNumber(event.target.value)))
        }
      >
        {bonuses.map((bonus) => (
          <MenuItem key={bonus.level} value={bonus.bonus}>
            <Grid container>
              <Grid item xs>
                {t('Level')} {bonus.level}
              </Grid>

              <Grid item>{bonus.bonus}%</Grid>
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
}

export default BonusSelection;
