import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { selectPlayerName } from '../../store/ranking/ranking.selectors';
import { handleChangePlayerName } from '../../store/ranking/ranking.store';
import { useTypedSelector } from '../../store/root.store';

function RankingConfiguration() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const playerName = useTypedSelector(selectPlayerName);
  const playerNameRef = React.useRef<HTMLInputElement>();

  const setPlayerName = () => {
    const inputElement = playerNameRef.current;

    if (inputElement && inputElement.value) {
      const playerName = inputElement.value;

      dispatch(handleChangePlayerName(playerName));
    }
  };

  return (
    <Dialog open={!playerName} onEscapeKeyDown={() => history.push('/c')}>
      <DialogTitle>{t('ranking.configuration.title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('ranking.configuration.description')}
        </DialogContentText>

        <TextField
          name="playerName"
          type="text"
          size="small"
          color="primary"
          label={t('ranking.configuration.title')}
          margin="dense"
          inputRef={playerNameRef}
          autoFocus
          fullWidth
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setPlayerName();
            }
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => history.push('/c')}>
          {t('ranking.configuration.cancel')}
        </Button>

        <Button color="primary" onClick={() => setPlayerName()}>
          {t('ranking.configuration.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RankingConfiguration;
