import toNumber from 'lodash/toNumber';

import { nanoid } from '@reduxjs/toolkit';

/**
 * Generate room id.
 * @param {number} length - The length of the new room id.
 */
function generateRoomId(length?: number) {
  const idLength =
    length || toNumber(process.env.REACT_APP_RANKING_ROOM_ID_LENGTH);

  if (!idLength) {
    throw new Error('Cannot generate roomId');
  }

  return nanoid(idLength);
}

export default generateRoomId;
