import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  INVESTMENT_LOCAL_STORAGE_KEY_BUILDING,
  INVESTMENT_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
  LOCAL_STORAGE_KEY_BONUS,
} from '../../constants/app.constants';
import { BuildingRouterParamModel } from '../../models/common.models';
import { selectSelectedBuildingName } from '../../store/investment/investment.selectors';
import {
  handleChangeSelectedBonus,
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelTo,
} from '../../store/investment/investment.store';
import { useTypedSelector } from '../../store/root.store';
import {
  setBonusRouterState,
  setBuildingRouterState,
} from '../../utils/setRouterState';
import AdSenseSquare from '../common/AdSenseSquare';
import BuildingImage from '../common/BuildingImage';

import InvestmentOverview from './InvestmentOverview';
import InvestmentSettings from './InvestmentSettings';

import useStyles from './Investment.styles';

function Investment() {
  const classes = useStyles();
  const { t } = useTranslation();
  const params = useParams<BuildingRouterParamModel>();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setBonusRouterState(
      { handleChangeSelectedBonus },
      { bonusKey: LOCAL_STORAGE_KEY_BONUS },
      dispatch,
    );

    setBuildingRouterState(
      params,
      {
        handleChangeSelectedBuilding,
        handleChangeSelectedBuildingLevel: handleChangeSelectedBuildingLevelTo,
      },
      {
        buildingNameKey: INVESTMENT_LOCAL_STORAGE_KEY_BUILDING,
        buildingLevelKey: INVESTMENT_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
      },
      dispatch,
    );

    // Redirect without params
    if (params.buildingName) {
      history.replace('/i');
    }
  }, [dispatch, history, params]);

  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Helmet>
        <title>
          {t('main.investment.title')} [App] | {t('main.app.title')}
        </title>

        <meta name="description" content={t('main.investment.description')} />
      </Helmet>

      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="h5" component="h2">
            {t('main.investment.title')}
          </Typography>

          <Typography color="textSecondary" variant="body2" gutterBottom>
            {t('main.investment.description')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <BuildingImage selectedBuildingName={selectedBuildingName} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <InvestmentSettings />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <InvestmentOverview />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <AdSenseSquare />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Investment;
