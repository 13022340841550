import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  offset: theme.mixins.toolbar,

  content: {
    padding: theme.spacing(3),
    flexGrow: 1,
  },
}));

export default useStyles;
