import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  LOCAL_STORAGE_KEY_PLAYER_NAME,
  RANKING_LOCAL_STORAGE_KEY_BUILDING,
  RANKING_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
  RANKING_LOCAL_STORAGE_KEY_FACTOR,
} from '../../constants/app.constants';
import {
  ChangePlaceAllocationModel,
  ChangePlaceConfigurationModel,
  RankingPlaceMap,
  RankingStateModel,
  WebSocketMessageModel,
} from '../../models/ranking.models';
import { createPlaceMap } from '../../utils/createPlaces';
import { getCommonPlaceInitialState } from '../../utils/getInitialState';
import * as reducerFn from '../../utils/reducerFn';

function createRankings() {
  return createPlaceMap<RankingPlaceMap>({
    isEnabled: false,
    playerName: '',
  });
}

const initialState = {
  ...getCommonPlaceInitialState(),
  owner: '',
  roomId: '',
  closedAt: null,
  createdAt: null,
  rankings: createRankings(),
  isWebSocketConnected: false,
};

export const handleChangeRoomId = createAction<string>(
  'ranking/handleChangeRoomId',
);

export const handleCloseRanking = createAction<string>(
  'ranking/handleCloseRanking',
);

export const handleCreateRanking = createAction<string>(
  'ranking/handleCreateRanking',
);

export const handleChangePlayerName = createAction<string>(
  'ranking/handleChangePlayerName',
);

export const handleWebSocketMessage = createAction<WebSocketMessageModel>(
  'ranking/handleWebSocketMessage',
);

export const handleWebSocketConnected = createAction(
  'ranking/handleWebSocketConnected',
);

export const handleWebSocketDisconnected = createAction(
  'ranking/handleWebSocketDisconnected',
);

export const handleChangeSelectedFactor = createAction<number>(
  'ranking/handleChangeSelectedFactor',
);

export const handleChangePlaceAllocation = createAction<
  ChangePlaceAllocationModel
>('ranking/handleChangePlaceAllocation');

export const handleChangeSelectedBuilding = createAction<string>(
  'ranking/handleChangeSelectedBuilding',
);

export const handleChangePlaceConfiguration = createAction<
  ChangePlaceConfigurationModel
>('ranking/handleChangePlaceConfiguration');

export const handleChangeSelectedBuildingLevelTo = createAction<number>(
  'ranking/handleChangeSelectedBuildingLevelTo',
);

export const handleChangeSelectedBuildingLevelFrom = createAction<number>(
  'ranking/handleChangeSelectedBuildingLevelFrom',
);

export const rankingReducer = createReducer<RankingStateModel>(
  initialState,

  (builder) =>
    builder
      .addCase(handleChangeRoomId, (state, { payload }) => {
        state.roomId = payload;
      })
      .addCase(handleCloseRanking, (state) => {
        state.closedAt = String(new Date());
      })
      .addCase(handleCreateRanking, (state) => {
        state.createdAt = String(new Date());
      })
      .addCase(handleChangePlayerName, (state, { payload }) =>
        reducerFn.changePlayerName(
          state,
          payload,
          LOCAL_STORAGE_KEY_PLAYER_NAME,
        ),
      )
      .addCase(handleWebSocketMessage, (state, { payload }) => {
        const {
          owner,
          factor = null,
          building = null,
          closedAt = null,
          createdAt = null,
          buildingLevel = null,
        } = payload;

        state.owner = owner;
        state.closedAt = closedAt;
        state.createdAt = createdAt;

        if (factor) {
          reducerFn.changeSelectedFactor(state, toNumber(factor));
        }

        if (building) {
          reducerFn.changeSelectedBuilding(state, building);
        }

        if (buildingLevel) {
          reducerFn.changeSelectedBuildingLevelToGroup(
            state,
            toNumber(buildingLevel),
          );
        }

        // reset current rankings
        state.rankings = createPlaceMap<RankingPlaceMap>((key: number) => {
          return {
            isEnabled: get(payload, `configuration:${key}`, false) === 'true',
            playerName: get(payload, `allocation:${key}`, ''),
          };
        });
      })
      .addCase(handleWebSocketConnected, (state) => {
        state.isWebSocketConnected = true;
      })
      .addCase(handleWebSocketDisconnected, (state) => {
        state.isWebSocketConnected = false;
      })
      .addCase(handleChangeSelectedFactor, (state, { payload }) =>
        reducerFn.changeSelectedFactor(
          state,
          payload,
          RANKING_LOCAL_STORAGE_KEY_FACTOR,
        ),
      )
      .addCase(handleChangePlaceAllocation, (state, { payload }) => {
        const { place, playerName } = payload;

        state.rankings[place].playerName = playerName;
      })
      .addCase(handleChangeSelectedBuilding, (state, { payload }) =>
        reducerFn.changeSelectedBuilding(
          state,
          payload,
          RANKING_LOCAL_STORAGE_KEY_BUILDING,
        ),
      )
      .addCase(handleChangePlaceConfiguration, (state, { payload }) => {
        const { place, isEnabled } = payload;

        state.rankings[place].isEnabled = isEnabled;
      })
      .addCase(handleChangeSelectedBuildingLevelTo, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelToGroup(
          state,
          payload,
          RANKING_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
        ),
      )
      .addCase(handleChangeSelectedBuildingLevelFrom, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelFrom(state, payload),
      ),
);
