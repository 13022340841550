import { RankingStateModel } from '../../models/ranking.models';
import * as rootSelectors from '../root.selectors';
import { RootStateModel } from '../root.store';

/**
 * Ranking selector
 * @param {RootStateModel} state - The root state.
 */
const rankingSelector = (state: RootStateModel): RankingStateModel =>
  state.ranking;

/**
 * Select owner.
 */
export const selectOwner = rootSelectors.createSelectOwner(rankingSelector);

/**
 * Select room id.
 */
export const selectRoomId = rootSelectors.createSelectRoomId(rankingSelector);

/**
 * Select is owner.
 */
export const selectIsOwner = rootSelectors.createSelectIsOwner(rankingSelector);

/**
 * Select rankings.
 */
export const selectRankings = rootSelectors.createSelectRankings(
  rankingSelector,
);

/**
 * Select buildings.
 */
export const selectBuildings = rootSelectors.createSelectBuildings(
  rankingSelector,
);

/**
 * Select closed at.
 */
export const selectClosedAt = rootSelectors.createSelectClosedAt(
  rankingSelector,
);

/**
 * Select created at.
 */
export const selectCreatedAt = rootSelectors.createSelectCreatedAt(
  rankingSelector,
);

/**
 * Select places data.
 */
export const selectPlacesData = rootSelectors.createSelectPlacesData(
  rankingSelector,
);

/**
 * Select player name.
 */
export const selectPlayerName = rootSelectors.createSelectPlayerName(
  rankingSelector,
);

/**
 * Select building levels.
 */
export const selectBuildingLevels = rootSelectors.createSelectBuildingLevels(
  rankingSelector,
);

/**
 * Select selected factor.
 */
export const selectSelectedFactor = rootSelectors.createSelectSelectedFactor(
  rankingSelector,
);

/**
 * Select is web socket connected.
 */
export const selectIsWebSocketConnected = rootSelectors.createSelectIsWebSocketConnected(
  rankingSelector,
);

/**
 * Select selected building name.
 */
export const selectSelectedBuildingName = rootSelectors.createSelectSelectedBuildingName(
  rankingSelector,
);

/**
 * Select selected building level to.
 */
export const selectSelectedBuildingLevelTo = rootSelectors.createSelectSelectedBuildingLevelTo(
  rankingSelector,
);

/**
 * Select selected building level from .
 */
export const selectSelectedBuildingLevelFrom = rootSelectors.createSelectSelectedBuildingLevelFrom(
  rankingSelector,
);
