import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import max from 'lodash/max';
import min from 'lodash/min';
import subtract from 'lodash/subtract';
import sum from 'lodash/sum';
import toNumber from 'lodash/toNumber';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

import useStyles from './BuildingLevelSelection.styles';

type BuildingLevelSelectionProps = {
  buildingLevels: Array<number>;
  selectedBuildingLevelTo: number;
  selectedBuildingLevelFrom: number;
  canChangeSelectedBuildingLevelTo: boolean;
  canChangeSelectedBuildingLevelFrom: boolean;
  handleChangeSelectedBuildingLevelTo: (
    selectedBuildingLevelTo: number,
  ) => void;
  handleChangeSelectedBuildingLevelFrom: (
    selectedBuildingLevelFrom: number,
  ) => void;
};

function BuildingLevelSelection({
  buildingLevels,
  selectedBuildingLevelTo,
  selectedBuildingLevelFrom,
  canChangeSelectedBuildingLevelTo,
  canChangeSelectedBuildingLevelFrom,
  handleChangeSelectedBuildingLevelTo,
  handleChangeSelectedBuildingLevelFrom,
}: BuildingLevelSelectionProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const maxBuildingLevel = max(buildingLevels);

  const nextSelectedBuildingLevel = min([
    maxBuildingLevel,
    sum([selectedBuildingLevelTo, 1]),
  ]) as number;

  const prevSelectedBuildingLevel = max([
    1,
    subtract(selectedBuildingLevelTo, 1),
  ]) as number;

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs>
        {canChangeSelectedBuildingLevelFrom && (
          <>
            <Typography color="textSecondary" variant="caption" component="p">
              {t('settings.levelFrom')}
            </Typography>

            <Select
              value={selectedBuildingLevelFrom}
              fullWidth
              onChange={(event) =>
                dispatch(
                  handleChangeSelectedBuildingLevelFrom(
                    toNumber(event.target.value),
                  ),
                )
              }
            >
              {buildingLevels.map((buildingLevel) => (
                <MenuItem key={buildingLevel - 1} value={buildingLevel - 1}>
                  {buildingLevel - 1}
                </MenuItem>
              ))}
            </Select>
          </>
        )}

        {!canChangeSelectedBuildingLevelFrom && (
          <div className={classes.chipWrapper}>
            <Chip
              label={selectedBuildingLevelFrom}
              variant="outlined"
              className={classes.chip}
            />
          </div>
        )}
      </Grid>

      <Grid item xs className={classes.chipWrapper}>
        <TrendingFlatIcon />
      </Grid>

      <Grid item xs>
        {canChangeSelectedBuildingLevelTo && (
          <>
            <Typography color="textSecondary" variant="caption" component="p">
              {t('settings.levelTo')}
            </Typography>

            <Select
              value={selectedBuildingLevelTo}
              fullWidth
              onChange={(event) =>
                dispatch(
                  handleChangeSelectedBuildingLevelTo(
                    toNumber(event.target.value),
                  ),
                )
              }
            >
              {buildingLevels
                .filter((buildingLevel) =>
                  canChangeSelectedBuildingLevelFrom
                    ? buildingLevel > selectedBuildingLevelFrom
                    : buildingLevel,
                )
                .map((buildingLevel) => (
                  <MenuItem key={buildingLevel} value={buildingLevel}>
                    {buildingLevel}
                  </MenuItem>
                ))}
            </Select>
          </>
        )}

        {!canChangeSelectedBuildingLevelTo && (
          <div className={classes.chipWrapper}>
            <Chip
              label={selectedBuildingLevelTo}
              variant="outlined"
              className={classes.chip}
            />
          </div>
        )}
      </Grid>

      {(canChangeSelectedBuildingLevelTo ||
        canChangeSelectedBuildingLevelFrom) && (
        <Grid item>
          <ButtonGroup size="small" orientation="vertical">
            <Button
              onClick={() =>
                dispatch(
                  handleChangeSelectedBuildingLevelTo(
                    nextSelectedBuildingLevel,
                  ),
                )
              }
            >
              <ExpandLessIcon />
            </Button>

            <Button
              onClick={() =>
                dispatch(
                  handleChangeSelectedBuildingLevelTo(
                    prevSelectedBuildingLevel,
                  ),
                )
              }
            >
              <ExpandMoreIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      )}
    </Grid>
  );
}

export default BuildingLevelSelection;
