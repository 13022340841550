/**
 * Round value to nearest number.
 * @param {number} value - The value to round.
 * @param {number} precision - The rounding precision.
 */
function roundNearest(value: number, precision: number) {
  return Math.round(value / precision) * precision;
}

export default roundNearest;
