import React from 'react';

import MuiSnackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import useStyles from './Snackbar.styles';

type SnackbarProps = {
  open: boolean;
  message: string;
  handleClose: () => void;
};

function Snackbar({ open, message, handleClose }: SnackbarProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiSnackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert
          variant="filled"
          severity="success"
          elevation={6}
          onClose={handleClose}
        >
          {message}
        </Alert>
      </MuiSnackbar>
    </div>
  );
}

export default Snackbar;
