import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Chip from '@material-ui/core/Chip';

import { ConfigurationHistoryModel } from '../../models/common.models';
import trackEvent from '../../utils/trackEvent';

import useStyles from './ConfigurationHistory.styles';

type ConfigurationHistoryProps = {
  linkPrefix: string;
  configurationHistory: ConfigurationHistoryModel[];
};

function ConfigurationHistory({
  linkPrefix,
  configurationHistory,
}: ConfigurationHistoryProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  if (!configurationHistory.length) {
    return null;
  }

  return (
    <div className={classes.root}>
      {configurationHistory.map((configuration) => {
        const { buildingName, buildingLevel } = configuration;

        const link = `/${linkPrefix}/${buildingName}/${buildingLevel}`;

        return (
          <Chip
            to={link}
            key={link}
            label={`${t(`building.${buildingName}`)} [${buildingLevel}]`}
            clickable
            component={RouterLink}
            onClick={() => {
              trackEvent('Use Configuration History', link);
            }}
          />
        );
      })}
    </div>
  );
}

export default ConfigurationHistory;
