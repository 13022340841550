import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},

  paper: {
    display: 'inline-block',
    padding: theme.spacing(2),
  },

  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  button: {
    marginLeft: 'auto',
  },

  button2: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
