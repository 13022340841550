/**
 * Get local storage value by key.
 * @param {string} key - The local storage key.
 * @return {string | undefined} - The local storage value.
 */
export function getLocalStorage(key: string) {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key);
  }
}

/**
 * Set local storage value for key.
 * @param {string} key - The local storage key.
 * @param {any} value - The local storage value.
 */
export function setLocalStorage(key: string, value: any) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value);
  }
}
