import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

type PlayerNameProps = {
  playerName: string;
  handleChangePlayerName: (playerName: string) => void;
};

function PlayerName({ playerName, handleChangePlayerName }: PlayerNameProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Paper elevation={0}>
      <Typography color="textSecondary" variant="caption" component="p">
        {t('settings.player')}
      </Typography>

      <TextField
        value={playerName}
        fullWidth
        onChange={(event) =>
          dispatch(handleChangePlayerName(event.target.value))
        }
      />
    </Paper>
  );
}

export default PlayerName;
