import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import CssBaseline from '@material-ui/core/CssBaseline';
import {
  ThemeProvider,
  unstable_createMuiStrictModeTheme,
} from '@material-ui/core/styles';

import AppBar from './components/base/AppBar';
import Drawer from './components/base/Drawer';
import useThemeMode from './hooks/useThemeMode';
import Routes from './Routes';

import useStyles from './App.styles';

function App() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [theme, toggleThemeMode] = useThemeMode();
  const [navigationOpen, setNavigationOpen] = React.useState(false);

  const handleDrawerToggle = () => setNavigationOpen(!navigationOpen);
  const isThemeModeDark = theme.palette.type === 'dark';

  return (
    <ThemeProvider theme={unstable_createMuiStrictModeTheme(theme)}>
      <div className={classes.root} data-tour="app-general">
        <CssBaseline />

        <Helmet>
          <meta charSet="utf-8" />
          <title>App | {t('main.app.title')}</title>

          <meta name="robots" content="index, follow" />
          <meta name="description" content={t('main.app.description')} />
        </Helmet>

        <AppBar handleDrawerToggle={handleDrawerToggle} />

        <Drawer
          navigationOpen={navigationOpen}
          isThemeModeDark={isThemeModeDark}
          toggleThemeMode={toggleThemeMode}
          handleDrawerToggle={handleDrawerToggle}
        />

        <main className={classes.content}>
          <div className={classes.offset} />

          <Routes />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
