import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING,
  PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
  PLAYBOOK_LOCAL_STORAGE_KEY_FACTOR,
} from '../../constants/app.constants';
import { PlaybookStateModel } from '../../models/playbook.models';
import { getCommonPlaceInitialState } from '../../utils/getInitialState';
import * as reducerFn from '../../utils/reducerFn';

const initialState = {
  ...getCommonPlaceInitialState(),
  selectedBuildingLevelTo: 2,
  selectedBuildingLevelFrom: 1,
};

export const handleChangePlayerName = createAction<string>(
  'playbook/handleChangePlayerName',
);

export const handleChangeSelectedFactor = createAction<number>(
  'playbook/handleChangeSelectedFactor',
);

export const handleChangeSelectedBuilding = createAction<string>(
  'playbook/handleChangeSelectedBuilding',
);

export const handleChangeSelectedBuildingLevelTo = createAction<number>(
  'playbook/handleChangeSelectedBuildingLevelTo',
);

export const handleChangeSelectedBuildingLevelFrom = createAction<number>(
  'playbook/handleChangeSelectedBuildingLevelFrom',
);

export const playbookReducer = createReducer<PlaybookStateModel>(
  initialState,

  (builder) =>
    builder
      .addCase(handleChangePlayerName, (state, { payload }) => {
        state.playerName = payload;
      })
      .addCase(handleChangeSelectedFactor, (state, { payload }) =>
        reducerFn.changeSelectedFactor(
          state,
          payload,
          PLAYBOOK_LOCAL_STORAGE_KEY_FACTOR,
        ),
      )
      .addCase(handleChangeSelectedBuilding, (state, { payload }) =>
        reducerFn.changeSelectedBuilding(
          state,
          payload,
          PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING,
        ),
      )
      .addCase(handleChangeSelectedBuildingLevelTo, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelTo(
          state,
          payload,
          PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
        ),
      )
      .addCase(handleChangeSelectedBuildingLevelFrom, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelFrom(state, payload),
      ),
);
