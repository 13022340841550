import add from 'lodash/add';
import find from 'lodash/find';
import map from 'lodash/map';
import max from 'lodash/max';
import subtract from 'lodash/subtract';
import toNumber from 'lodash/toNumber';

import { TFunction } from 'i18next';
import { createIdSelector, createSelector, Selector } from 'redux-views';

import {
  CommonPlaceStateModel,
  CommonStateModel,
} from '../models/common.models';
import { ContributionStateModel } from '../models/contribution.models';
import { InvestmentStateModel } from '../models/investment.models';
import { PlaybookStateModel } from '../models/playbook.models';
import { RankingStateModel } from '../models/ranking.models';
import * as selectorFn from '../utils/selectorFn';

export const createSelectOwner = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) => createSelector([baseSelector], (state) => state.owner);

export const createSelectPlaces = <S>(
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) => createSelector([baseSelector], (state) => state.places);

export const createSelectRoomId = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) => createSelector([baseSelector], (state) => state.roomId);

export const createSelectBonuses = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) => createSelector([baseSelector], (state) => state.bonuses);

export const createSelectRankings = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) => createSelector([baseSelector], (state) => state.rankings);

export const createSelectBuildings = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) => createSelector([baseSelector], (state) => state.buildings);

export const createSelectClosedAt = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) => createSelector([baseSelector], (state) => state.closedAt);

export const createSelectCreatedAt = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) => createSelector([baseSelector], (state) => state.createdAt);

export const createSelectPlayerName = <S>(
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) => createSelector([baseSelector], (state) => state.playerName);

export const createSelectSelectedBonus = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) => createSelector([baseSelector], (state) => state.selectedBonus);

export const createSelectSelectedFactor = <S>(
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) => createSelector([baseSelector], (state) => state.selectedFactor);

export const createSelectOwnContribution = <S>(
  baseSelector: Selector<S, ContributionStateModel>,
) => createSelector([baseSelector], (state) => state.ownContribution);

export const createSelectSelectedHighestBid = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) => createSelector([baseSelector], (state) => state.selectedHighestBid);

export const createSelectIsWebSocketConnected = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) => createSelector([baseSelector], (state) => state.isWebSocketConnected);

export const createSelectSelectedBuildingName = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) => createSelector([baseSelector], (state) => state.selectedBuilding);

export const createSelectSelectedContribution = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) => createSelector([baseSelector], (state) => state.selectedContribution);

export const createSelectSelectedLevelCostTotal = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) => createSelector([baseSelector], (state) => state.selectedLevelCostTotal);

export const createSelectSelectedBuildingLevelTo = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) => createSelector([baseSelector], (state) => state.selectedBuildingLevelTo);

export const createSelectSelectedBuildingLevelFrom = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) => createSelector([baseSelector], (state) => state.selectedBuildingLevelFrom);

export const createSelectConfigurationHistory = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) => createSelector([baseSelector], (state) => state.configurationHistory);

type SelectSelectedBuildingLevelToProps = {
  selectedBuildingLevelTo: number;
};

export const createSelectIsOwner = <S>(
  baseSelector: Selector<S, RankingStateModel>,
) =>
  createSelector(
    [createSelectOwner(baseSelector), createSelectPlayerName(baseSelector)],
    (owner, playerName) => {
      return owner === playerName;
    },
  );

export const createSelectSelectedBuildingLevelToWithProps = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [
      baseSelector,
      createIdSelector<SelectSelectedBuildingLevelToProps>((props) =>
        props.selectedBuildingLevelTo.toString(),
      ),
    ],
    (state, selectedBuildingLevelTo) => {
      if (selectedBuildingLevelTo) {
        return toNumber(selectedBuildingLevelTo);
      }

      return state.selectedBuildingLevelTo;
    },
  );

type SelectSelectedBuildingLevelFromProps = {
  selectedBuildingLevelFrom: number;
};

export const createSelectSelectedBuildingLevelFromWithProps = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [
      baseSelector,
      createIdSelector<SelectSelectedBuildingLevelFromProps>((props) =>
        props.selectedBuildingLevelFrom.toString(),
      ),
    ],
    (state, selectedBuildingLevelFrom) => {
      if (selectedBuildingLevelFrom) {
        return toNumber(selectedBuildingLevelFrom);
      }

      return state.selectedBuildingLevelFrom;
    },
  );

export const createSelectSelectedBuilding = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [
      createSelectBuildings(baseSelector),
      createSelectSelectedBuildingName(baseSelector),
    ],
    (buildings, selectedBuildingName) =>
      find(buildings, ['name', selectedBuildingName]),
  );

export const createSelectBuildingLevels = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [createSelectSelectedBuilding(baseSelector)],
    (selectedBuilding) => {
      if (!selectedBuilding) {
        return [];
      }

      return map(selectedBuilding.levels, 'level');
    },
  );

export const createSelectBuildingLevel = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [
      createSelectSelectedBuilding(baseSelector),
      createSelectSelectedBuildingLevelTo(baseSelector),
    ],
    selectorFn.selectBuildingLevel,
  );

export const createSelectBuildingLevelWithProps = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [
      createSelectSelectedBuilding(baseSelector),
      createSelectSelectedBuildingLevelToWithProps(baseSelector),
    ],
    selectorFn.selectBuildingLevel,
  );

export const createSelectBuildingLevelReward = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [createSelectBuildingLevel(baseSelector)],
    selectorFn.selectBuildingLevelReward,
  );

export const createSelectBuildingLevelRewardWithProps = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [createSelectBuildingLevelWithProps(baseSelector)],
    selectorFn.selectBuildingLevelReward,
  );
export const createSelectBuildingLevelCostTotal = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [createSelectBuildingLevel(baseSelector)],
    selectorFn.selectBuildingLevelCostTotal,
  );

export const createSelectBuildingLevelCostTotalWithProps = <S>(
  baseSelector: Selector<S, CommonStateModel>,
) =>
  createSelector(
    [createSelectBuildingLevelWithProps(baseSelector)],
    selectorFn.selectBuildingLevelCostTotal,
  );

export const createSelectPlacesData = <S>(
  baseSelector: Selector<
    S,
    ContributionStateModel | PlaybookStateModel | RankingStateModel
  >,
) =>
  createSelector(
    [
      createSelectPlaces(baseSelector),
      createSelectBuildingLevelReward(baseSelector),
      createSelectBuildingLevelCostTotal(baseSelector),
    ],
    selectorFn.selectPlacesData,
  );

export const createSelectPlacesDataWithProps = <S>(
  baseSelector: Selector<S, PlaybookStateModel>,
) =>
  createSelector(
    [
      createSelectPlaces(baseSelector),
      createSelectBuildingLevelRewardWithProps(baseSelector),
      createSelectBuildingLevelCostTotalWithProps(baseSelector),
    ],
    selectorFn.selectPlacesData,
  );

export const createSelectSummaryLines = <S>(
  t: TFunction,
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) =>
  createSelector(
    [
      createSelectPlaces(baseSelector),
      createSelectPlacesData(baseSelector),
      createSelectPlayerName(baseSelector),
      createSelectSelectedBuildingName(baseSelector),
      createSelectBuildingLevelCostTotal(baseSelector),
      createSelectSelectedBuildingLevelTo(baseSelector),
    ],
    selectorFn.makeSelectSummaryLines(t),
  );

export const createSelectSummaryLinesWithProps = <S>(
  t: TFunction,
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) =>
  createSelector(
    [
      createSelectPlaces(baseSelector),
      createSelectPlacesDataWithProps(baseSelector),
      createSelectPlayerName(baseSelector),
      createSelectSelectedBuildingName(baseSelector),
      createSelectBuildingLevelCostTotal(baseSelector),
      createSelectSelectedBuildingLevelToWithProps(baseSelector),
    ],
    selectorFn.makeSelectSummaryLines(t),
  );

export const createSelectPlacesSummaryLines = <S>(
  t: TFunction,
  baseSelector: Selector<S, CommonPlaceStateModel>,
) =>
  createSelector(
    [
      createSelectPlacesData(baseSelector),
      createSelectPlayerName(baseSelector),
      createSelectSelectedBuildingName(baseSelector),
      createSelectBuildingLevelCostTotal(baseSelector),
      createSelectSelectedBuildingLevelTo(baseSelector),
    ],
    selectorFn.makeSelectPlacesSummaryLines(t),
  );

export const createSelectPlacesOwnContributionTotal = <S>(
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) =>
  createSelector(
    [
      createSelectPlacesData(baseSelector),
      createSelectBuildingLevelCostTotal(baseSelector),
    ],
    selectorFn.selectPlacesOwnContributionTotal,
  );

export const createSelectPlacesOwnContributionTotalWithProps = <S>(
  baseSelector: Selector<S, ContributionStateModel | PlaybookStateModel>,
) =>
  createSelector(
    [
      createSelectPlacesDataWithProps(baseSelector),
      createSelectBuildingLevelCostTotalWithProps(baseSelector),
    ],
    selectorFn.selectPlacesOwnContributionTotal,
  );

export const createSelectPlacesCostContributionTotal = <S>(
  baseSelector: Selector<S, CommonPlaceStateModel>,
) =>
  createSelector([createSelectPlacesData(baseSelector)], (placesData) => {
    return max(
      map(placesData, (placeData) => placeData.costContributionSubtotal),
    );
  });

export const createSelectPlacesRewardContributionTotal = <S>(
  baseSelector: Selector<S, CommonPlaceStateModel>,
) =>
  createSelector([createSelectPlacesData(baseSelector)], (placesData) => {
    return max(
      map(placesData, (placeData) => placeData.rewardContributionSubtotal),
    );
  });

export const createSelectBuildingLevelCostRemaining = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) =>
  createSelector(
    [
      createSelectSelectedContribution(baseSelector),
      createSelectBuildingLevelCostTotal(baseSelector),
      createSelectSelectedLevelCostTotal(baseSelector),
    ],
    (selectedContribution, buildingLevelCostTotal, selectedLevelCostTotal) => {
      const levelCostTotal = selectedLevelCostTotal || buildingLevelCostTotal;

      return max([0, subtract(levelCostTotal, selectedContribution)]) as number;
    },
  );

export const createSelectInvestment = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) =>
  createSelector(
    [
      createSelectSelectedHighestBid(baseSelector),
      createSelectSelectedContribution(baseSelector),
      createSelectBuildingLevelCostRemaining(baseSelector),
    ],
    (selectedHighestBid, selectedContribution, buildingLevelCostRemaining) => {
      if (buildingLevelCostRemaining <= 0) {
        return 0;
      }

      if (selectedHighestBid > selectedContribution) {
        return 0;
      }

      const totalBuildingLevelCostRemaining = add(
        selectedHighestBid,
        buildingLevelCostRemaining,
      );

      const investment = Math.ceil(totalBuildingLevelCostRemaining / 2);

      if (investment === selectedHighestBid) {
        return 0;
      }

      if (investment > buildingLevelCostRemaining) {
        return 0;
      }

      return investment;
    },
  );

export const createSelectReward = <S>(
  baseSelector: Selector<S, InvestmentStateModel>,
) =>
  createSelector(
    [
      createSelectInvestment(baseSelector),
      createSelectSelectedBonus(baseSelector),
    ],
    (investment, selectedBonus) => {
      const factor = add(100, selectedBonus) / 100;

      return Math.ceil(investment / factor);
    },
  );
