import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import toNumber from 'lodash/toNumber';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import {
  CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING,
  CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
  CONTRIBUTION_LOCAL_STORAGE_KEY_CONFIGURATION_HISTORY,
} from '../../constants/app.constants';
import { BuildingRouterParamModel } from '../../models/common.models';
import {
  makeSelectPlacesSummaryLines,
  selectConfigurationHistory,
  selectPlaces,
  selectPlacesData,
  selectSelectedBuildingName,
} from '../../store/contribution/contribution.selectors';
import {
  handleChangePlayerName,
  handleChangeSelectedBuilding,
  handleChangeSelectedBuildingLevelTo,
  handleInitConfigurationHistory,
} from '../../store/contribution/contribution.store';
import { useTypedSelector } from '../../store/root.store';
import { getLocalStorage } from '../../utils/localStorage';
import setPlayerNameState from '../../utils/setPlayerNameState';
import { setBuildingRouterState } from '../../utils/setRouterState';
import AdSenseSquare from '../common/AdSenseSquare';
import BuildingImage from '../common/BuildingImage';
import ConfigurationHistory from '../common/ConfigurationHistory';

import ContributionPlace from './ContributionPlace';
import ContributionSettings from './ContributionSettings';
import ContributionSummary from './ContributionSummary';

import useStyles from './Contribution.styles';

function Contribution() {
  const classes = useStyles();
  const { t } = useTranslation();
  const params = useParams<BuildingRouterParamModel>();
  const history = useHistory();
  const dispatch = useDispatch();

  React.useEffect(() => {
    setPlayerNameState(handleChangePlayerName, dispatch);

    setBuildingRouterState(
      params,
      {
        handleChangeSelectedBuilding,
        handleChangeSelectedBuildingLevel: handleChangeSelectedBuildingLevelTo,
      },
      {
        buildingNameKey: CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING,
        buildingLevelKey: CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
      },
      dispatch,
    );

    const configurationHistory = getLocalStorage(
      CONTRIBUTION_LOCAL_STORAGE_KEY_CONFIGURATION_HISTORY,
    );

    if (configurationHistory) {
      try {
        const configurationHistoryData = JSON.parse(configurationHistory);

        dispatch(handleInitConfigurationHistory(configurationHistoryData));
      } catch {}
    }

    // Redirect without params
    if (params.buildingName) {
      history.replace('/c');
    }
  }, [dispatch, history, params]);

  const places = useTypedSelector(selectPlaces);
  const placesData = useTypedSelector(selectPlacesData);
  const placesSummaryLines = useTypedSelector(makeSelectPlacesSummaryLines(t));
  const selectedBuildingName = useTypedSelector(selectSelectedBuildingName);
  const configurationHistory = useTypedSelector(selectConfigurationHistory);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="h5" component="h2">
            {t('main.contribution.title')}
          </Typography>

          <Typography color="textSecondary" variant="body2" gutterBottom>
            {t('main.contribution.description')}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ConfigurationHistory
            linkPrefix="c"
            configurationHistory={configurationHistory}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <BuildingImage selectedBuildingName={selectedBuildingName} />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <ContributionSettings />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <ContributionSummary />
        </Grid>

        {Object.keys(placesData).map((placeNumber) => (
          <Grid key={placeNumber} item xs={12} sm={12} md={6} lg={4}>
            <ContributionPlace
              placeData={placesData[placeNumber]}
              placePaid={places[placeNumber].paid}
              placeFactor={places[placeNumber].factor}
              placeNumber={toNumber(placeNumber)}
              placeIsSelected={places[placeNumber].isSelected}
              placeSummaryLine={placesSummaryLines[placeNumber]}
            />
          </Grid>
        ))}

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <AdSenseSquare />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Contribution;
