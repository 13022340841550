export const FACTOR_MIN = 0;
export const FACTOR_MAX = 2;
export const FACTOR_STEP = 0.05;

/**
 * Create local storage key.
 * @param {string} name - The name of the local storage key.
 * @return {string} The created local storage key with prefix.
 */
function createLocalStorageKey(name: string) {
  return `foeBoost${name.charAt(0).toUpperCase()}${name.slice(1)}`;
}

// Global

export const LOCAL_STORAGE_KEY_BONUS = createLocalStorageKey('Bonus');

export const LOCAL_STORAGE_KEY_THEME_TYPE = createLocalStorageKey('ThemeType');

export const LOCAL_STORAGE_KEY_PLAYER_NAME = createLocalStorageKey(
  'PlayerName',
);

// Contribution

export const CONTRIBUTION_LOCAL_STORAGE_KEY_FACTOR = createLocalStorageKey(
  'CFactor',
);

export const CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING = createLocalStorageKey(
  'CBuilding',
);

export const CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING_LEVEL = createLocalStorageKey(
  'CBuildingLevel',
);

export const CONTRIBUTION_LOCAL_STORAGE_KEY_CONFIGURATION_HISTORY = createLocalStorageKey(
  'CConfigurationHistory',
);

// Investment

export const INVESTMENT_LOCAL_STORAGE_KEY_BUILDING = createLocalStorageKey(
  'IBuilding',
);

export const INVESTMENT_LOCAL_STORAGE_KEY_BUILDING_LEVEL = createLocalStorageKey(
  'IBuildingLevel',
);

// Playbook

export const PLAYBOOK_LOCAL_STORAGE_KEY_FACTOR = createLocalStorageKey(
  'PFactor',
);

export const PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING = createLocalStorageKey(
  'PBuilding',
);

export const PLAYBOOK_LOCAL_STORAGE_KEY_BUILDING_LEVEL = createLocalStorageKey(
  'PBuildingLevel',
);

// Ranking

export const RANKING_LOCAL_STORAGE_KEY_FACTOR = createLocalStorageKey(
  'RFactor',
);

export const RANKING_LOCAL_STORAGE_KEY_BUILDING = createLocalStorageKey(
  'RBuilding',
);

export const RANKING_LOCAL_STORAGE_KEY_BUILDING_LEVEL = createLocalStorageKey(
  'RBuildingLevel',
);
