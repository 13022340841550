import { InvestmentStateModel } from '../../models/investment.models';
import * as rootSelectors from '../root.selectors';
import { RootStateModel } from '../root.store';

const investmentSelector = (state: RootStateModel): InvestmentStateModel =>
  state.investment;

export const selectReward = rootSelectors.createSelectReward(
  investmentSelector,
);

export const selectBonuses = rootSelectors.createSelectBonuses(
  investmentSelector,
);

export const selectBuildings = rootSelectors.createSelectBuildings(
  investmentSelector,
);

export const selectInvestment = rootSelectors.createSelectInvestment(
  investmentSelector,
);

export const selectSelectedBonus = rootSelectors.createSelectSelectedBonus(
  investmentSelector,
);

export const selectBuildingLevels = rootSelectors.createSelectBuildingLevels(
  investmentSelector,
);

export const selectSelectedHighestBid = rootSelectors.createSelectSelectedHighestBid(
  investmentSelector,
);

export const selectSelectedBuildingName = rootSelectors.createSelectSelectedBuildingName(
  investmentSelector,
);

export const selectSelectedContribution = rootSelectors.createSelectSelectedContribution(
  investmentSelector,
);

export const selectSelectedLevelCostTotal = rootSelectors.createSelectSelectedLevelCostTotal(
  investmentSelector,
);

export const selectSelectedBuildingLevelTo = rootSelectors.createSelectSelectedBuildingLevelTo(
  investmentSelector,
);

export const selectSelectedBuildingLevelFrom = rootSelectors.createSelectSelectedBuildingLevelFrom(
  investmentSelector,
);

export const selectBuildingLevelCostRemaining = rootSelectors.createSelectBuildingLevelCostRemaining(
  investmentSelector,
);
