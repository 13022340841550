import toNumber from 'lodash/toNumber';

import { createAction, createReducer } from '@reduxjs/toolkit';

import {
  CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING,
  CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
  CONTRIBUTION_LOCAL_STORAGE_KEY_CONFIGURATION_HISTORY,
  CONTRIBUTION_LOCAL_STORAGE_KEY_FACTOR,
  LOCAL_STORAGE_KEY_PLAYER_NAME,
} from '../../constants/app.constants';
import {
  ChangePlaceFactorModel,
  ChangePlacePaidModel,
  ConfigurationHistoryModel,
  TogglePlaceIsSelectedModel,
} from '../../models/common.models';
import { ContributionStateModel } from '../../models/contribution.models';
import { getCommonPlaceInitialState } from '../../utils/getInitialState';
import * as reducerFn from '../../utils/reducerFn';

const initialState = {
  ...getCommonPlaceInitialState(),
  ownContribution: 0,
};

export const handleChangePlacePaid = createAction<ChangePlacePaidModel>(
  'contribution/handleChangePlacePaid',
);

export const handleChangePlaceFactor = createAction<ChangePlaceFactorModel>(
  'contribution/handleChangePlaceFactor',
);

export const handleChangePlayerName = createAction<string>(
  'contribution/handleChangePlayerName',
);

export const handleChangeSelectedFactor = createAction<number>(
  'contribution/handleChangeSelectedFactor',
);

export const handleChangeOwnContribution = createAction<number>(
  'contribution/handleChangeOwnContribution',
);

export const handleTogglePlaceIsSelected = createAction<
  TogglePlaceIsSelectedModel
>('contribution/handleTogglePlaceIsSelected');

export const handleChangeSelectedBuilding = createAction<any>(
  'contribution/handleChangeSelectedBuilding',
);

export const handleChangeSelectedBuildingLevelTo = createAction<number>(
  'contribution/handleChangeSelectedBuildingLevelTo',
);

export const handleChangeSelectedBuildingLevelFrom = createAction<number>(
  'contribution/handleChangeSelectedBuildingLevelFrom',
);

export const handleInitConfigurationHistory = createAction<
  ConfigurationHistoryModel[]
>('contribution/handleInitConfigurationHistory');

export const handleChangeConfigurationHistory = createAction<void>(
  'contribution/handleChangeConfigurationHistory',
);

export const contributionReducer = createReducer<ContributionStateModel>(
  initialState,

  (builder) =>
    builder
      .addCase(handleChangePlacePaid, (state, { payload }) => {
        state.places[payload.place].paid = toNumber(payload.paid);
      })
      .addCase(handleChangePlaceFactor, (state, { payload }) => {
        state.places[payload.place].factor = toNumber(payload.factor);
      })
      .addCase(handleChangePlayerName, (state, { payload }) =>
        reducerFn.changePlayerName(
          state,
          payload,
          LOCAL_STORAGE_KEY_PLAYER_NAME,
        ),
      )
      .addCase(handleChangeSelectedFactor, (state, { payload }) =>
        reducerFn.changeSelectedFactor(
          state,
          payload,
          CONTRIBUTION_LOCAL_STORAGE_KEY_FACTOR,
        ),
      )
      .addCase(handleChangeOwnContribution, (state, { payload }) => {
        state.ownContribution = payload;
      })
      .addCase(handleTogglePlaceIsSelected, (state, { payload }) => {
        state.places[payload.place].isSelected = !state.places[payload.place]
          .isSelected;
      })
      .addCase(handleChangeSelectedBuilding, (state, { payload }) =>
        reducerFn.changeSelectedBuilding(
          state,
          payload,
          CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING,
        ),
      )
      .addCase(handleChangeSelectedBuildingLevelTo, (state, { payload }) =>
        reducerFn.changeSelectedBuildingLevelToGroup(
          state,
          payload,
          CONTRIBUTION_LOCAL_STORAGE_KEY_BUILDING_LEVEL,
        ),
      )
      .addCase(handleInitConfigurationHistory, (state, { payload }) => {
        state.configurationHistory = payload;
      })
      .addCase(handleChangeConfigurationHistory, (state) => {
        const configurationHistory = {
          buildingName: state.selectedBuilding,
          buildingLevel: state.selectedBuildingLevelTo,
        };

        return reducerFn.changeConfigurationHistory(
          state,
          configurationHistory,
          CONTRIBUTION_LOCAL_STORAGE_KEY_CONFIGURATION_HISTORY,
        );
      }),
);
