import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import max from 'lodash/max';
import min from 'lodash/min';
import round from 'lodash/round';
import subtract from 'lodash/subtract';
import sum from 'lodash/sum';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  FACTOR_MAX,
  FACTOR_MIN,
  FACTOR_STEP,
} from '../../constants/app.constants';

type FactorSelectionProps = {
  selectedFactor: number;
  handleChangeSelectedFactor: (selectedFactor: number) => void;
};

function FactorSelection({
  selectedFactor,
  handleChangeSelectedFactor,
}: FactorSelectionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nextSelectedFactor = round(
    min([FACTOR_MAX, sum([selectedFactor, FACTOR_STEP])]) as number,
    2,
  );

  const prevSelectedFactor = round(
    max([FACTOR_MIN, subtract(selectedFactor, FACTOR_STEP)]) as number,
    2,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs>
        <Typography color="textSecondary" variant="caption" component="p">
          {t('settings.factor')}
        </Typography>

        <TextField
          type="number"
          value={selectedFactor}
          fullWidth
          inputProps={{
            min: FACTOR_MIN,
            max: FACTOR_MAX,
            step: FACTOR_STEP,
          }}
          onChange={(event) =>
            dispatch(handleChangeSelectedFactor(parseFloat(event.target.value)))
          }
        />
      </Grid>

      <Grid item>
        <ButtonGroup size="small" orientation="vertical">
          <Button
            onClick={() =>
              dispatch(handleChangeSelectedFactor(nextSelectedFactor))
            }
          >
            <ExpandLessIcon />
          </Button>

          <Button
            onClick={() =>
              dispatch(handleChangeSelectedFactor(prevSelectedFactor))
            }
          >
            <ExpandMoreIcon />
          </Button>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}

export default FactorSelection;
