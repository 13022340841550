declare global {
  interface Window {
    _paq: Array<String[]>;
  }
}

/**
 * Track event.
 * @param {string} category - The event category.
 * @param {string} name - The event name.
 */
function trackEvent(category: string, name: string) {
  if (process.env.REACT_APP_EVENT_TRACKING_ENABLED === 'true') {
    const _paq = window._paq || [];

    _paq.push(['trackEvent', category, name]);
  } else {
    console.log('[DRY] trackEvent: %s, %s', category, name);
  }
}

export default trackEvent;
