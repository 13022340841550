import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Contribution from './components/Contribution/Contribution';
import Investment from './components/Investment/Investment';
import Playbook from './components/Playbook/Playbook';
import RankingContent from './components/Ranking/Ranking';
import generateRoomId from './utils/generateRoomId';

function Routes() {
  const roomId = generateRoomId();

  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/c" />
      </Route>

      <Route path="/c/:buildingName?/:buildingLevel?">
        <Contribution />
      </Route>

      <Route path="/i/:buildingName?/:buildingLevel?">
        <Investment />
      </Route>

      <Route path="/p/:buildingName?/:buildingLevel?">
        <Playbook />
      </Route>

      <Route
        path={`/r/:roomId([A-Za-z0-9_-]{${process.env.REACT_APP_RANKING_ROOM_ID_LENGTH}})`}
        exact
      >
        <RankingContent />
      </Route>

      <Route path="/r/:buildingName/:buildingLevel?">
        <RankingContent />
      </Route>

      <Redirect to={`/r/${roomId}`} from="/r" />

      <Route path="*">
        <Redirect to="/c" />
      </Route>
    </Switch>
  );
}

export default Routes;
