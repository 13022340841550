import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import join from 'lodash/join';
import toNumber from 'lodash/toNumber';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import copyToClipboard from 'copy-to-clipboard';

import {
  makeSelectSummaryLines,
  selectBuildingLevelCostTotal,
  selectOwnContribution,
  selectPlacesCostContributionTotal,
  selectPlacesOwnContributionTotal,
} from '../../store/contribution/contribution.selectors';
import {
  handleChangeConfigurationHistory,
  handleChangeOwnContribution,
} from '../../store/contribution/contribution.store';
import { useTypedSelector } from '../../store/root.store';
import trackEvent from '../../utils/trackEvent';
import Snackbar from '../base/Snackbar';

import useStyles from './ContributionSummary.styles';

function ContributionSummary() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const summaryLines = useTypedSelector(makeSelectSummaryLines(t));
  const ownContribution = useTypedSelector(selectOwnContribution);
  const buildingLevelCostTotal = useTypedSelector(selectBuildingLevelCostTotal);
  const placesOwnContributionTotal = useTypedSelector(
    selectPlacesOwnContributionTotal,
  );
  const placesCostContributionTotal = useTypedSelector(
    selectPlacesCostContributionTotal,
  );

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Typography align="center" variant="h5" component="p">
            {t('summary.title')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('summary.totalLevelCost')}
          </Typography>

          <Typography align="center" variant="h6" component="p">
            {buildingLevelCostTotal}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('summary.totalBoostCost')}
          </Typography>

          <Typography align="center" variant="h6" component="p">
            {placesCostContributionTotal}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('summary.totalOwnContribution')}
          </Typography>

          <Typography align="center" variant="h6" component="p">
            {placesOwnContributionTotal}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('summary.ownContribution')}
          </Typography>

          <TextField
            type="number"
            value={ownContribution || ''}
            disabled
            fullWidth
            onChange={(event) =>
              dispatch(
                handleChangeOwnContribution(toNumber(event.target.value)),
              )
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('summary.boostLink')}
          </Typography>

          <div className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="outlined"
              className={classes.button}
              onClick={() => {
                trackEvent('Copy to Clipboard', 'Contribution Link');

                copyToClipboard(join(summaryLines, ' '));
                dispatch(handleChangeConfigurationHistory());

                setOpen(true);
              }}
            >
              <div className={classes.summary}>
                {summaryLines.map((summaryLine, index) => (
                  <div key={index}>{summaryLine}</div>
                ))}
              </div>
            </Button>
          </div>
        </Grid>
      </Grid>

      <Snackbar
        open={open}
        message={t('snackbar.success.copied')}
        handleClose={() => setOpen(false)}
      />
    </Paper>
  );
}

export default ContributionSummary;
