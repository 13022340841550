import theArc from '../data/buildings/the-arc.json';
import config from '../data/config.json';
import {
  CommonPlaceMapModel,
  CommonPlaceStateModel,
  CommonStateModel,
} from '../models/common.models';

import { createPlaceMap } from './createPlaces';
import { getBuildings } from './getData';

/**
 * Get common initial state.
 */
export function getCommonInitialState(): CommonStateModel {
  return {
    buildings: getBuildings(),
    selectedBuilding: theArc.name,
    configurationHistory: [],
    selectedBuildingLevelTo: 1,
    selectedBuildingLevelFrom: 0,
  };
}

/**
 * Get common place initial state.
 */
export function getCommonPlaceInitialState(): CommonPlaceStateModel {
  return {
    ...getCommonInitialState(),
    places: createPlaceMap<CommonPlaceMapModel>({
      paid: 0,
      factor: config.mainFactor,
      isSelected: config.mainSelection,
    }),
    playerName: '',
    selectedFactor: config.mainFactor,
  };
}
