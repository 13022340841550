import { Dispatch } from 'react';

import toNumber from 'lodash/toNumber';

import theArc from '../data/buildings/the-arc.json';
import {
  BonusRouterActionModel,
  BonusRouterLocalStorageModel,
  BuildingRouterActionModel,
  BuildingRouterLocalStorageModel,
  BuildingRouterParamModel,
} from '../models/common.models';
import { handleChangeSelectedBonus } from '../store/investment/investment.store';

import {
  getValidBonus,
  getValidBuilding,
  getValidBuildingLevel,
} from './getData';
import { getLocalStorage } from './localStorage';

export function setBonusRouterState(
  actions: BonusRouterActionModel,
  localStorageKeys: BonusRouterLocalStorageModel,
  dispatch: Dispatch<any>,
) {
  const { bonusKey } = localStorageKeys;

  const lsBonus = getLocalStorage(bonusKey);

  if (lsBonus) {
    const validBonus = getValidBonus(toNumber(lsBonus));

    if (validBonus) {
      dispatch(handleChangeSelectedBonus(validBonus.bonus));
    }
  }
}

/**
 * Set building router state.
 * @param {BuildingRouterParamModel} params - The building router params.
 * @param {BuildingRouterActionModel} actions - The building router actions.
 * @param {BuildingRouterLocalStorageModel} localStorageKeys - The building local storage keys.
 * @param {Dispatch<any>} dispatch - The redux dispatch function.
 */
export function setBuildingRouterState(
  params: BuildingRouterParamModel,
  actions: BuildingRouterActionModel,
  localStorageKeys: BuildingRouterLocalStorageModel,
  dispatch: Dispatch<any>,
) {
  const { buildingName, buildingLevel } = params;

  const {
    handleChangeSelectedBuilding,
    handleChangeSelectedBuildingLevel,
  } = actions;

  const { buildingNameKey, buildingLevelKey } = localStorageKeys;

  let validBuilding;
  let validBuildingLevel;

  if (buildingName) {
    validBuilding = getValidBuilding(buildingName);
  }

  if (!validBuilding) {
    const lsBuildingName = getLocalStorage(buildingNameKey);

    if (lsBuildingName) {
      validBuilding = getValidBuilding(lsBuildingName);
    }
  }

  if (!validBuilding) {
    validBuilding = getValidBuilding(theArc.name);
  }

  if (validBuilding) {
    dispatch(handleChangeSelectedBuilding(validBuilding.name));

    if (buildingLevel) {
      validBuildingLevel = getValidBuildingLevel(
        validBuilding.name,
        toNumber(buildingLevel),
      );
    }

    if (!validBuildingLevel) {
      const lsBuildingLevel = getLocalStorage(buildingLevelKey);

      if (lsBuildingLevel) {
        validBuildingLevel = getValidBuildingLevel(
          validBuilding.name,
          toNumber(lsBuildingLevel),
        );
      }
    }

    if (validBuildingLevel) {
      dispatch(handleChangeSelectedBuildingLevel(validBuildingLevel.level));
    }
  }
}
