import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import toNumber from 'lodash/toNumber';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import {
  selectBuildingLevelCostRemaining,
  selectInvestment,
  selectReward,
  selectSelectedContribution,
  selectSelectedHighestBid,
  selectSelectedLevelCostTotal,
} from '../../store/investment/investment.selectors';
import {
  handleChangeSelectedContribution,
  handleChangeSelectedHighestBid,
  handleChangeSelectedLevelCostTotal,
} from '../../store/investment/investment.store';
import { useTypedSelector } from '../../store/root.store';
import ContributionSelection from '../common/ContributionSelection';

import InvestmentHighestBid from './InvestmentHighestBid';

import useStyles from './InvestmentOverview.styles';

function InvestmentOverview() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const reward = useTypedSelector(selectReward);
  const investment = useTypedSelector(selectInvestment);
  const selectedHighestBid = useTypedSelector(selectSelectedHighestBid);
  const selectedContribution = useTypedSelector(selectSelectedContribution);
  const selectedLevelCostTotal = useTypedSelector(selectSelectedLevelCostTotal);
  const buildingLevelCostRemaining = useTypedSelector(
    selectBuildingLevelCostRemaining,
  );

  return (
    <Paper className={classes.root}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={6}>
          <Typography color="textSecondary" variant="caption" component="p">
            {t('investment.overview.totalLevelCost')}
          </Typography>

          <TextField
            type="number"
            value={selectedLevelCostTotal === 0 ? '' : selectedLevelCostTotal}
            fullWidth
            inputProps={{
              inputMode: 'numeric',
            }}
            onFocus={(event) => event.target.select()}
            onChange={(event) =>
              dispatch(
                handleChangeSelectedLevelCostTotal(
                  toNumber(event.target.value),
                ),
              )
            }
          />
        </Grid>

        <Grid item xs={6}>
          <ContributionSelection
            selectedContribution={selectedContribution}
            handleChangeSelectedContribution={handleChangeSelectedContribution}
          />
        </Grid>

        <Grid item xs={6}>
          <Typography color="textSecondary" variant="caption" component="p">
            {t('investment.overview.remainingLevelCost')}
          </Typography>

          <Typography variant="h6" component="p">
            {buildingLevelCostRemaining}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <InvestmentHighestBid
            selectedHighestBid={selectedHighestBid}
            handleChangeSelectedHighestBid={handleChangeSelectedHighestBid}
          />
        </Grid>
      </Grid>

      <Divider light variant="middle" className={classes.divider} />

      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item xs={6}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('investment.overview.investment')}
          </Typography>

          <Typography align="center" variant="h5" component="p">
            {investment}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption"
            component="p"
          >
            {t('investment.overview.reward')}
          </Typography>

          <Typography align="center" variant="h5" component="p">
            {reward}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default InvestmentOverview;
