import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import sortBy from 'lodash/sortBy';

import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

import { BuildingListModel } from '../../models/common.models';

type BuildingSelectionProps = {
  buildings: BuildingListModel;
  selectedBuildingName: string;
  handleChangeSelectedBuilding: (selectedBuilding: string) => void;
};

function BuildingSelection({
  buildings,
  selectedBuildingName,
  handleChangeSelectedBuilding,
}: BuildingSelectionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const labeledBuildings: BuildingListModel = [];

  for (const building of buildings) {
    labeledBuildings.push({
      ...building,
      label: t(`building.${building.name}`),
    })
  }

  const sortedBuildings = sortBy(labeledBuildings, ['label']);

  return (
    <Paper elevation={0}>
      <Typography color="textSecondary" variant="caption" component="p">
        {t('settings.building')}
      </Typography>

      <Select
        value={selectedBuildingName}
        fullWidth
        onChange={(event) =>
          dispatch(handleChangeSelectedBuilding(String(event.target.value)))
        }
      >
        {sortedBuildings.map((building) => (
          <MenuItem key={building.name} value={building.name}>
            {building.label}
          </MenuItem>
        ))}
      </Select>
    </Paper>
  );
}

export default BuildingSelection;
