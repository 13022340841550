import find from 'lodash/find';
import join from 'lodash/join';
import map from 'lodash/map';
import max from 'lodash/max';
import toNumber from 'lodash/toNumber';

import { TFunction } from 'i18next';

import {
  BuildingLevelModel,
  BuildingModel,
  CommonPlaceDataMapModel,
  CommonPlaceDataModel,
  CommonPlaceMapModel,
  CommonPlaceSummaryMapModel,
} from '../models/common.models';

import { createPlaceMap } from './createPlaces';
import roundNearest from './roundNearest';

/**
 * Select building level.
 * @param selectedBuilding - The selected building.
 * @param selectedBuildingLevelTo - The selected building level to.
 */
export function selectBuildingLevel(
  selectedBuilding: BuildingModel | undefined,
  selectedBuildingLevelTo: number,
): BuildingLevelModel | undefined {
  if (selectedBuilding) {
    return find(selectedBuilding.levels, ['level', selectedBuildingLevelTo]);
  }
}

/**
 * Select building level reward.
 * @param buildingLevel - The selected building level.
 */
export function selectBuildingLevelReward(
  buildingLevel: BuildingLevelModel | undefined,
) {
  if (!buildingLevel) {
    return 0;
  }

  return buildingLevel.reward;
}

/**
 * Select building level cost total.
 * @param buildingLevel - The selected building level.
 */
export function selectBuildingLevelCostTotal(
  buildingLevel: BuildingLevelModel | undefined,
) {
  if (!buildingLevel) {
    return 0;
  }

  return buildingLevel.cost;
}

/**
 * Select places data.
 * @param places - The places.
 * @param buildingLevelReward - The building level reward.
 * @param buildingLevelCostTotal - The building level cost total.
 */
export function selectPlacesData(
  places: CommonPlaceMapModel,
  buildingLevelReward: number,
  buildingLevelCostTotal: number,
) {
  let rewardContribution = buildingLevelReward;

  let placesOwnContributionSubtotal = 0;
  let placesCostContributionSubtotal = 0;
  let placesRewardContributionSubtotal = 0;

  return createPlaceMap<CommonPlaceDataMapModel>((key: number) => {
    const placeData = {} as CommonPlaceDataModel;

    // Reward Contribution
    rewardContribution = roundNearest(rewardContribution / key, 5);
    placeData.rewardContribution = rewardContribution;

    // Reward Contribution Subtotal
    const rewardContributionSubtotal =
      placesRewardContributionSubtotal + placeData.rewardContribution;

    placeData.rewardContributionSubtotal = rewardContributionSubtotal;
    placesRewardContributionSubtotal = rewardContributionSubtotal;

    // Cost Contribution
    const { factor } = places[key];
    placeData.costContribution = max([
      1,
      Math.ceil(placeData.rewardContribution * factor),
    ]) as number;

    // Cost Contribution Subtotal
    const costContributionSubtotal =
      placesCostContributionSubtotal + placeData.costContribution;

    placeData.costContributionSubtotal = costContributionSubtotal;
    placesCostContributionSubtotal = costContributionSubtotal;

    // Own Contribution
    const ownContribution =
      buildingLevelCostTotal -
      placesOwnContributionSubtotal -
      placesCostContributionSubtotal -
      placeData.costContribution;

    placeData.ownContribution = max([0, ownContribution]) as number;

    // Own Contribution Subtotal
    const ownContributionSubtotal =
      placesOwnContributionSubtotal + placeData.ownContribution;

    placeData.ownContributionSubtotal = ownContributionSubtotal;
    placesOwnContributionSubtotal = ownContributionSubtotal;

    return placeData;
  });
}

/**
 * Select places own contribution total.
 * @param {CommonPlaceDataMapModel} placesData - The places data.
 * @param {number} buildingLevelCostTotal - The building level cost total.
 * @return {number} The places own contribution total.
 */
export function selectPlacesOwnContributionTotal(
  placesData: CommonPlaceDataMapModel,
  buildingLevelCostTotal: number,
) {
  const costContributionTotal = max(
    map(placesData, (placeData) => placeData.costContributionSubtotal),
  ) as number;

  return buildingLevelCostTotal - costContributionTotal;
}

const getPlaceDangerInfo = (
  placesData: CommonPlaceDataMapModel,
  placeNumber: number,
  buildingLevelCostTotal: number,
) => {
  const costContribution = placesData[placeNumber].costContribution;
  const ownContributionSubtotal =
    placesData[placeNumber].ownContributionSubtotal;
  const costContributionSubtotal =
    placesData[placeNumber].costContributionSubtotal;

  const contributionSubtotal =
    costContributionSubtotal + ownContributionSubtotal;

  const contribution = contributionSubtotal - costContribution;

  // Calculate minimum investment for the current place
  const minimumInvestment = Math.ceil(
    (buildingLevelCostTotal - contribution) / 2,
  );

  let dangerInfo = '';

  if (minimumInvestment > 0 && minimumInvestment < costContribution) {
    dangerInfo = ' D';
  }

  return dangerInfo;
};

/**
 * Make select summary lines.
 * @param {TFunction} t - The translation function.
 */
export function makeSelectSummaryLines(t: TFunction) {
  /**
   * Select summary lines.
   * @param {CommonPlaceMapModel} places - The places.
   * @param {CommonPlaceDataMapModel} placesData - The places data.
   * @param {string} playerName - The player name.
   * @param {string} selectedBuildingName - The selected building name.
   * @param {number} selectedBuildingLevelTo - The selected building level to.
   */
  return function selectSummaryLines(
    places: CommonPlaceMapModel,
    placesData: CommonPlaceDataMapModel,
    playerName: string,
    selectedBuildingName: string,
    buildingLevelCostTotal: number,
    selectedBuildingLevelTo: number,
  ) {
    const summaryLine1Items: Array<string> = [];
    const summaryLine2Items: Array<string> = [];

    if (playerName) {
      summaryLine1Items.push(playerName);
    }

    const buildingSlugName = t(`buildingSlug.${selectedBuildingName}`);

    summaryLine1Items.push(
      `${buildingSlugName}[${
        selectedBuildingLevelTo - 1
      }/${selectedBuildingLevelTo}]`,
    );

    Object.keys(placesData)
      .reverse()
      .map((place) => {
        const placeIsSelected = places[place].isSelected;

        if (placeIsSelected) {
          const placeCostContribution = placesData[place].costContribution;

          const dangerInfo = getPlaceDangerInfo(
            placesData,
            toNumber(place),
            buildingLevelCostTotal,
          );
          summaryLine2Items.push(
            `P${place}(${placeCostContribution}${dangerInfo})`,
          );
        }

        return null;
      });

    const summaryLine1 = join(summaryLine1Items, ' ');
    const summaryLine2 = join(summaryLine2Items, ' ');

    return [summaryLine1, summaryLine2];
  };
}

/**
 * Make select places summary lines.
 * @param {TFunction} t - The translation function.
 */
export function makeSelectPlacesSummaryLines(t: TFunction) {
  /**
   * Select places summary lines.
   * @param placesData
   * @param playerName
   * @param selectedBuildingName
   * @param selectedBuildingLevelTo
   */
  return function selectPlacesSummaryLines(
    placesData: CommonPlaceDataMapModel,
    playerName: string,
    selectedBuildingName: string,
    buildingLevelCostTotal: number,
    selectedBuildingLevelTo: number,
  ) {
    const summaryLineItems: Array<string> = [];

    if (playerName) {
      summaryLineItems.push(playerName);
    }

    const buildingSlugName = t(`buildingSlug.${selectedBuildingName}`);

    summaryLineItems.push(
      `${buildingSlugName}[${
        selectedBuildingLevelTo - 1
      }/${selectedBuildingLevelTo}]`,
    );

    return createPlaceMap<CommonPlaceSummaryMapModel>((key: number) => {
      const placeCostContribution = placesData[key].costContribution;

      const dangerInfo = getPlaceDangerInfo(
        placesData,
        key,
        buildingLevelCostTotal,
      );

      const placeCostContributionLine = `P${key}(${placeCostContribution}${dangerInfo})`;

      return join([...summaryLineItems, placeCostContributionLine], ' ');
    });
  };
}
