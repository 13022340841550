import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    padding: theme.spacing(2),
  },

  media: {
    height: 200,
    backgroundSize: 'contain',
  },
}));

export default useStyles;
