import { combineReducers } from '@reduxjs/toolkit';

import { contributionReducer } from './contribution/contribution.store';
import { investmentReducer } from './investment/investment.store';
import { playbookReducer } from './playbook/playbook.store';
import { rankingReducer } from './ranking/ranking.store';

/**
 * Root reducer
 */
const rootReducer = combineReducers({
  ranking: rankingReducer,
  playbook: playbookReducer,
  investment: investmentReducer,
  contribution: contributionReducer,
});

export default rootReducer;
